
<layout>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <!-- Page Header: start -->
        <div class="md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <h2
              class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate"
            >Neuer Partner</h2>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <span class="sm:ml-3 shadow-sm rounded-md">
              <Link
                :href="route('partners.index')"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              >Übersicht</Link>
            </span>
          </div>
        </div>
        <!-- Page Header: end -->

        <horizontal-divider />

        <form @submit.prevent="submit" novalidate enctype="multipart/form-data">
          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Profil</h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-3 gap-6">
                      <div class="col-span-6 sm:col-span-4">
                        <label
                          for="name"
                          class="block text-sm font-medium leading-5 text-gray-700"
                        >Name</label>
                        <input
                        type="text"

                          id="name"
                          v-model="form.name"
                          :disabled="form.processing"
                          class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="mt-6">
                      <label
                        for="note"
                        class="block text-sm leading-5 font-medium text-gray-700"
                      >Bemerkungen</label>
                      <div class="mt-1">
                        <textarea
                          id="note"
                          v-model="form.note"
                          :disabled="form.processing"
                          rows="5"
                          class="shadow-sm focus:ring-gray-400 focus:border-gray-400 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <horizontal-divider />

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Logo</h3>
                  <p
                    class="mt-1 text-sm leading-5 text-gray-600"
                  >Maximale Logo-Abmessungen: bla bla</p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="mt-2 flex items-center">
                      <span
                        class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100"
                      >
                        <svg
                          class="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                          />
                        </svg>
                      </span>

                      <input
                        type="file"
                        id="file"
                        :disabled="form.processing"
                        :name="form.logo"
                        class="w-1 h-1 opacity-0 overflow-hidden absolute"
                        data-caption
                        accept="image/x-png, image/gif, image/jpeg"
                        @change="fileInputChange"
                      />
                      <label
                        for="file"
                        class="ml-4 inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-500 hover:text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-400 active:bg-gray-400 transition duration-150 ease-in-out cursor-pointer"
                      >{{ uploadButtonLabel }}</label>
                    </div>

                    <div class="grid grid-cols-6 gap-6 mt-8">
                      <div class="col-span-6 sm:col-span-2">
                        <label
                          for="logo_height"
                          class="block text-sm font-medium leading-5 text-gray-700"
                        >Höhe</label>

                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            id="logo_height"
                            v-model="form.logo_height"
                            :disabled="form.processing"
                            class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"

                            placeholder
                            maxlength="3"
                          />
                          <div
                            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                          >
                            <span class="text-gray-500 sm:text-sm sm:leading-5">px</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-6 sm:col-span-2">
                        <label
                          for="logo_margin_top"
                          class="block text-sm font-medium leading-5 text-gray-700"
                        >Abstand oben</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input
                          type="number"
                            id="logo_margin_top"
                            v-model="form.logo_margin_top"
                            :disabled="form.processing"
                            class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder
                            maxlength="3"
                          />
                          <div
                            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                          >
                            <span class="text-gray-500 sm:text-sm sm:leading-5">px</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          

          <div class="mt-8 border-t border-gray-200 pt-5">
            <div class="flex justify-end">
              <submit-button
                :sending="form.processing"
                :validated="validated"
                :full-width="false"
              >Speichern</submit-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</layout>
