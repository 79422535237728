<template>
  <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->

  <button 
    type="button"
    class="relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="toggleClasses"
    @click="toggle"
  >
    <!-- On: "translate-x-5", Off: "translate-x-0" -->
    <span
      class="relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
      :class="isEnabled ? 'translate-x-5' : 'translate-x-0'"
    >
      <!-- On: "opacity-0 ease-out duration-100", Off: "opacity-100 ease-in duration-200" -->
      <span
        class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
        :class="
          isEnabled
            ? 'opacity-0 ease-out duration-100'
            : 'opacity-100 ease-in duration-200'
        "
      >
        <svg
          v-if="!isEnabled"
          class="h-3 w-3 text-gray-400"
          fill="none"
          viewBox="0 0 12 12"
        >
          <path
            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <!-- On: "opacity-100 ease-in duration-200", Off: "opacity-0 ease-out duration-100" -->
      <span
        class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
        :class="
          isEnabled
            ? 'opacity-100 ease-in duration-200'
            : 'opacity-0 ease-out duration-100'
        "
      >
        <svg
          v-if="isEnabled"
          class="h-3 w-3 text-brandhelfer-green"
          fill="currentColor"
          viewBox="0 0 12 12"
        >
          <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
          />
        </svg>
      </span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    sending: {
      default: false,
    },
    idx: {
      default: 0,
    },
    enabled: {
      default: true,
    },
  },

  data() {
    return {
      isEnabled: this.enabled,
    };
  },

  watch: {
    enabled: function (newVal, oldVal) {
      this.isEnabled = newVal;
    },
  },

  methods: {
    toggle() {
      if (this.sending) {
        return false;
      }

      this.isEnabled = !this.isEnabled;

      this.$emit('toggled', this.idx, this.isEnabled);
    },
  },

  computed: {
    toggleClasses() {
      let classes = '';

      if (this.isEnabled) {
        classes += 'bg-brandhelfer-green ';
      } else {
        classes += 'bg-gray-200 ';
      }

      if (this.sending) {
        classes += 'opacity-50 cursor-default focus:ring-transparent';
      } else {
        classes += 'cursor-pointer focus:ring-brandhelfer-green';
      }

      return classes;
    },
  },
};
</script>
