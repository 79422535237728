<template>
  <div class="flex flex-row w-full">
    <div class="flex-grow">
      {{ select.name }}<br />

      <select
        v-model="selectedOption"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm rounded-md"
      >
        <option value="">---</option>
        <option v-for="(option, idx) in options" :key="idx" :value="option">
          {{ option }}
        </option>
      </select>

      <div v-if="hasColors" class="mt-2">
        <div
          v-for="(color, idx) in colors"
          :key="idx"
          :style="`background-color: rgba(${color},1)`"
          @click="changeColor(color)"
          class="w-2 h-2 p-4 rounded float-left mr-2 mb-2 border-2 border-gray-200 hover:border-brandhelfer-green cursor-pointer"
          :class="
            selectedColor == color
              ? 'border-brandhelfer-green cursor-default'
              : ''
          "
        ></div>
      </div>
    </div>
    <div class="w-1/5">
      <toggle :idx="select.id" @toggled="statusToggled"></toggle>
    </div>

    <horizontal-divider />
  </div>
</template>

<script>
import Toggle from '@/Shared/Toggle.vue';


export default {
  components: {
    Toggle,
    
  },

  props: {
    select: Object,
  },

  data() {
    return {
      selectedOption: this.select.default,
      selectedColor: this.select.defaultColor,
      options: this.select.options,
    };
  },

  mounted() {
    if (this.select.default) {
      this.$emit('optionSelected', this.select, this.select.default);
    }
  },

  watch: {
    selectedOption: {
      handler() {
        this.$emit('optionSelected', this.select, this.selectedOption);
      },
      deep: true,
    },
  },

  methods: {
    changeColor(color) {
      this.selectedColor = color;

      this.$emit('selectColorChanged', this.select, color);
    },

    statusToggled(idx, status) {
      this.$emit('selectStatusToggled', this.select, status);
    },
  },

  computed: {
    colors() {
      return this.select.colors;
    },

    hasColors() {
      return this.colors.length > 0;
    },
  },
};
</script>
