<template>
  <footer
    class="order-3 flex-shrink-0 w-full text-center border-t border-gray-300 relative z-20 inline-flex justify-evenly shadow-sm"
  >
    <a
      :href="route('social-graphics.select-template')"
      class="group inline-flex flex-col items-center py-2 px-1 border-b-2 font-medium text-sm w-1/3 focus:outline-none"
      :class="
        activeItem == 'new'
          ? 'border-brandhelfer-green text-brandhelfer-green'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
      "
    >
      <svg
        class="h-5 w-5"
        :class="
          activeItem == 'new'
            ? 'text-brandhelfer-green'
            : 'text-gray-400 group-hover:text-gray-500'
        "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <span>Neu</span>
    </a>

    <a
      :href="route('social-graphics.index')"
      class="group inline-flex flex-col items-center py-2 px-1 border-b-2 font-medium text-sm w-1/3 focus:outline-none"
      :class="
        activeItem == 'index'
          ? 'border-brandhelfer-green text-brandhelfer-green'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
      "
    >
      <svg
        class="h-5 w-5"
        :class="
          activeItem == 'index'
            ? 'text-brandhelfer-green'
            : 'text-gray-400 group-hover:text-gray-500'
        "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        />
      </svg>

      <span>Übersicht</span>
    </a>

    <!-- Current: "border-brandhelfer-green text-brandhelfer-green", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
    <button
      type="button"
      @click="moreMenuExpanded = !moreMenuExpanded"
      class="group inline-flex flex-col items-center py-2 px-1 border-b-2 font-medium text-sm w-1/3 focus:outline-none"
      :class="
        activeItem == 'more'
          ? 'border-brandhelfer-green text-brandhelfer-green'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
      "
    >
      <!-- Current: "text-brandhelfer-green", Default: "text-gray-400 group-hover:text-gray-500" -->
      <svg
        class="h-5 w-5"
        :class="
          activeItem == 'more'
            ? 'text-brandhelfer-green'
            : 'text-gray-400 group-hover:text-gray-500'
        "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>

      <span>Mehr</span>
    </button>

    <!--
    Dropdown panel, show/hide based on dropdown state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="moreMenuExpanded"
        class="origin-bottom-right absolute right-0 bottom-16 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div class="py-1">
          <Link
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            v-if="$page.props.auth.user.is.admin"
            :href="route('partners.index')"
            >Partner</Link
          >

          <Link
            v-if="$page.props.auth.user.is.admin"
            :href="route('users.index')"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            >User</Link
          >

          <Link
            v-if="$page.props.auth.user.is.admin"
            :href="route('templates.index')"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            >Vorlagen</Link
          >
        </div>
        <div class="py-1">
          <a
            href="#"
            @click="logout"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            >Logout</a
          >
        </div>
      </div>
    </transition>
  </footer>
</template>

<script>
import Profile from '@/Shared/Header/Profile.vue';
import Notification from '@/Shared/Header/Notification.vue';

export default {
  components: {
    Profile,
    Notification,
  },

  props: {
    activeItem: '',
  },

  data() {
    return {
      moreMenuExpanded: false,
    };
  },

  methods: {
    logout() {
      this.$inertia.post('/logout', {});
    },
  },
};
</script>