<template>
  <div>
    <p v-if="!log.length" class="text-sm font-medium text-gray-700">
      Es liegen noch keine Aktivitäten vor.
    </p>

    <div class="flow-root">
      <ul class="-mb-8">
        <li v-for="(activity, idx) in log" :key="activity.id">
          <div class="relative pb-8">
            <span
              v-if="idx < log.length - 1"
              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            ></span>
            <div class="relative flex space-x-3">
              <div>
                <log-icon :log="activity"></log-icon>
              </div>
              <div
                class="
                  min-w-0
                  flex-1
                  pt-1.5
                  sm:flex sm:justify-between sm:space-x-4
                "
              >
                <div>
                  <p class="text-sm text-gray-500">
                    {{ activityType(activity.description) }} {{ activity.id }}
                    <span class="font-medium text-gray-900">{{
                      activitySubject(activity.subject_type)
                    }}</span>
                    &quot;{{ activityDetail(activity) }}&quot;
                  </p>
                  <p class="mt-2 text-sm text-gray-400">
                    {{ activityChanges(activity) }}
                  </p>
                </div>
                <div
                  class="
                    sm:block sm:text-right
                    text-sm
                    whitespace-nowrap
                    text-gray-500
                  "
                >
                  <time>{{
                    $filters.formatDateTime(activity.created_at)
                  }}</time>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LogIcon from '@/Pages/Users/Shared/LogIcon.vue';
import { keys, values, reduce, isEqual, isPlainObject } from 'lodash';

export default {
  components: {
    LogIcon,
  },

  props: {
    log: Array,
  },

  filters: {
    beautify: function (value) {
      return JSON.stringify(value, undefined, 4);
    },
    fullType: function (value) {
      if (value == 'trait') return 'Eigenschaft';

      if (value == 'behaviour') return 'Verhalten';

      if (value == 'baseword') return 'Wert';

      if (value == 'brand') return 'Marke';

      return '-';
    },
  },

  methods: {
    activityType(type) {
      if (type == 'created') return 'Neuanlage';

      if (type == 'updated') return 'Änderung';

      if (type == 'deleted') return 'Löschung';

      if (type == 'downloaded') return 'Download';
    },

    activitySubject(subject) {
      if (subject == 'App\\Models\\Partner') return 'Partner';

      if (subject == 'App\\Models\\User') return 'User';

      if (subject == 'App\\Models\\SocialGraphic') return 'Social Graphic';

      if (subject == 'App\\Models\\Template') return 'Vorlage';
    },

    activityDetail(activity) {
      // oh, that's ugly

      if (activity.subject_type == 'App\\Models\\Partner') {
        if (activity.properties.attributes !== undefined)
          return activity.properties.attributes.name;
        if (activity.properties.old !== undefined)
          return activity.properties.old.name;
      }

      if (activity.subject_type == 'App\\Models\\User') {
        if (activity.properties.attributes !== undefined)
          return activity.properties.attributes.name;
        if (activity.properties.old !== undefined)
          return activity.properties.old.name;
      }

      if (activity.subject_type == 'App\\Models\\SocialGraphic') {
        if (activity.properties.attributes !== undefined)
          return activity.properties.attributes['template.name'];
        if (activity.properties.old !== undefined)
          return activity.properties.old['template.name'];
      }

      if (activity.subject_type == 'App\\Models\\Template') {
        if (activity.properties.attributes !== undefined)
          return activity.properties.attributes.name;
        if (activity.properties.old !== undefined)
          return activity.properties.old.name;
      }
    },

    activityChanges(activity) {
      if (activity.description != 'updated') {
        return '';
      }

      return this.formatChanges(
        this.objectDeepDiff(
          activity.properties.old,
          activity.properties.attributes
        )
      );
    },

    formatChanges(changes) {
      if (keys(changes).length > 0) {
        return `Alte Angabe(n): ${values(changes).join(', ')}`;
      }
    },

    objectDeepDiff(obj1, obj2) {
      return reduce(
        obj1,
        function (result, value, key) {
          if (isPlainObject(value)) {
            result[key] = diff(value, obj2[key]);
          } else if (!isEqual(value, obj2[key])) {
            result[key] = value;
          }
          return result;
        },
        {}
      );
    },
  },
};
</script>