
<div class="w-full">
  <display
    v-for="(option, idx) in display.options"
    :key="idx"
    :display="option"
    :enabled="option.enabled"
    @statusToggled="statusToggled"
    class="mb-6"
  ></display>
</div>
