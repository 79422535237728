
<div>

  <span 
    v-if="!partner.logo_link"
    class="inline-flex items-center justify-center w-auto bg-gray-200 rounded-md px-2"
    :class="`h-${height}`"
  >
    <span class="text-sm font-normal leading-5 text-gray-500">{{ partner.name }}</span>
  </span>

  

  <img v-if="partner.logo_link" class="max-h-8" :style="customCss" :src="partner.logo_link" alt />
</div>
