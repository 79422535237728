<template>
  <div class="sm:block">
    <div :class="paddingClass">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      type: Number,
      default: 5
    }
  },

  computed: {
    paddingClass() {
      return `py-${this.padding}`;
    }
  },
};
</script>