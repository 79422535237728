<template>
  <div>
    <div class="min-h-screen bg-gray-100">
      <slot></slot>

      <toast></toast>
    </div>
    
  </div>
</template>

<script>
import Toast from '@/Shared/Toast.vue';

export default {
  components: {
    Toast
  }
};
</script>
