
<div class="flex flex-row w-full">
  <div class="flex-grow">
    {{ color.name }}

    <div class="mt-2">
      <div
        v-for="(color, idx) in color.colors"
        :key="idx"
        :style="`background-color: rgba(${color},1)`"
        @click="changeColor(color)"
        class="w-2 h-2 p-4 rounded float-left mr-2 mb-2 border-2 border-gray-200 hover:border-brandhelfer-green cursor-pointer"
        :class="
          selectedColor == color
            ? 'border-brandhelfer-green cursor-default'
            : ''
        "
      ></div>
    </div>
  </div>
  <div class="w-1/5">
    <toggle
      :idx="color.id"
      :enabled="color.enabled"
      @toggled="statusToggled"
    ></toggle>
  </div>
</div>
