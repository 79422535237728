<template>
  <div class="h-full min-h-full flex flex-col pt-3">
    <form @submit.prevent="submit" novalidate>
      <!-- Top third: start -->
      <div class="order-1 flex-shrink-0 container mx-auto px-4">
        <!-- Page Header: start -->
        <div class="flex items-center justify-between">
          <div class="flex-1 min-w-0">
            <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
              <h2 class="ml-2 mt-2 text-2xl leading-7 font-light text-gray-900">
                Social Graphics
              </h2>
              <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
                Vorlage &quot;{{ template.name }}&quot;
              </p>
            </div>
          </div>
          <div>
            <!-- Floating submit button: start -->
            <button
              :disabled="busy"
              @click="store"
              class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-brandhelfer-green hover:bg-brandhelfer-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green"
            >
              <!-- Heroicon name: check -->

              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </button>
            <!-- Floating submit button: end -->
          </div>
        </div>
        <!-- Page Header: end -->

        <horizontal-divider :padding="2" />

        <!-- SVG / Section selection: start -->

        <div class="flex flex-row mb-4 container mx-auto px-0">
          <div class="w-9/12">
            <spinner :busy="svgBusy"></spinner>
            <div class="template shadow-md" v-html="svg"></div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="w-3/12 flex flex-col ml-3">
              <div v-if="hasBackgroundImage">
                <i
                  @click="activeSection = 'background-image'"
                  class="fa fa-fw fa-lg fa-image mb-4 cursor-pointer hover:text-brandhelfer-green"
                  :class="
                    activeSection == 'background-image'
                      ? 'text-brandhelfer-green'
                      : ''
                  "
                ></i>
              </div>

              <div v-if="hasBackgroundColors">
                <i
                  @click="activeSection = 'background-color'"
                  class="fa fa-fw fa-lg fa-paint-brush mb-4 cursor-pointer hover:text-brandhelfer-green"
                  :class="
                    activeSection == 'background-color'
                      ? 'text-brandhelfer-green'
                      : ''
                  "
                ></i>
              </div>

              <div v-if="hasTexts">
                <i
                  @click="activeSection = 'text'"
                  class="fa fa-fw fa-lg fa-font mb-4 cursor-pointer hover:text-brandhelfer-green"
                  :class="
                    activeSection == 'text' ? 'text-brandhelfer-green' : ''
                  "
                ></i>
              </div>

              <div v-if="hasExtras">
                <i
                  @click="activeSection = 'extras'"
                  class="fa fa-fw fa-lg fa-th-large mb-4 cursor-pointer hover:text-brandhelfer-green"
                  :class="
                    activeSection == 'extras' ? 'text-brandhelfer-green' : ''
                  "
                ></i>
              </div>
            </div>
          </div>
        </div>
        <!-- SVG / Section selection: end -->
      </div>
      <!-- Top third: end -->

      <!-- Middle third: start -->
      <div
        class="order-2 overflow-y-scroll bh-green-400 h-screen container mx-auto px-4 pt-2"
      >
        <!-- Background Image -->
        <div
          v-show="hasBackgroundImage && activeSection == 'background-image'"
          class="flex flex-row w-full"
        >
          <background-image
            :backgroundImage="backgroundImage"
            @imageUploading="backgroundImageUploading"
            @imageUploaded="backgroundImageUploaded"
            @statusToggled="backgroundImageStatusToggled"
          >
          </background-image>
        </div>

        <div class="flex-grow">
          <!-- Background Image -->
          <div
            v-show="
              hasBackgroundImage &&
              usesBackgroundImageLibrary &&
              activeSection == 'background-image'
            "
            class="flex flex-row w-full"
          >
            <background-image-library
              :template="template"
              @imageSelected="backgroundImageSelected"
            >
            </background-image-library>
          </div>
        </div>

        <!-- Background Color -->
        <transition name="fade">
          <div
            v-show="hasBackgroundColors && activeSection == 'background-color'"
          >
            <div
              class="flex flex-row w-full mb-4"
              v-for="backgroundColor in backgroundColors"
              :key="backgroundColor.id"
            >
              <background-color
                :smallscreen="true"
                :backgroundColor="backgroundColor"
                @colorSelected="backgroundColorSelected"
                @colorStatusToggled="backgroundColorStatusToggled"
              ></background-color>
            </div>
          </div>
        </transition>

        <!-- Text -->
        <transition name="fade">
          <div v-show="hasTexts && activeSection == 'text'" class="w-full">
            <div>Texte</div>

            <div class="mt-6">
              <div
                v-for="(text, idx) in texts"
                :key="`text${idx}`"
                class="flex flex-row w-full mb-6"
              >
                <textinput
                  v-if="text.type == 'textinput'"
                  :text="text"
                  :smallscreen="true"
                  @textEntered="textEntered"
                  @textColorChanged="textColorChanged"
                  @textStatusToggled="textStatusToggled"
                ></textinput>

                <selection
                  v-if="text.type == 'selection'"
                  :select="text"
                  @optionSelected="optionSelected"
                  @selectColorChanged="selectColorChanged"
                  @selectStatusToggled="selectStatusToggled"
                ></selection>
              </div>
            </div>
          </div>
        </transition>

        <!-- Extras -->
        <transition name="fade">
          <div v-show="hasExtras && activeSection == 'extras'" class="w-full">
            <div class="mt-6">
              <div
                v-for="(extra, idx) in extras"
                :key="`extra${idx}`"
                class="flex flex-row w-full mb-6"
              >
                <color
                  v-if="extra.type == 'color'"
                  :color="extra"
                  @colorChanged="extraColorChanged"
                  @statusToggled="extraColorStatusToggled"
                ></color>

                <display
                  v-if="extra.type == 'display'"
                  :display="extra"
                  @statusToggled="extraDisplayStatusToggled"
                ></display>

                <display-group
                  v-if="extra.type == 'displayGroup'"
                  :display="extra"
                  @statusToggled="extraDisplayStatusToggled"
                ></display-group>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- Middle third: end -->

      <!-- Bottom third: start -->
      <navbar-smallscreen active-item="new"></navbar-smallscreen>
      <!-- Bottom third: end -->
    </form>

    <pending-modal v-if="busy"></pending-modal>
  </div>
</template>

<script>
import NavbarSmallscreen from '@/Shared/Footer/NavbarSmallscreen.vue';

import SubmitButton from '@/Shared/SubmitButton.vue';
import PendingModal from '@/Shared/PendingModal.vue';
import Spinner from '@/Shared/Spinner.vue';
import Toggle from '@/Shared/Toggle.vue';
import BackgroundColor from '@/Shared/Fields/BackgroundColor.vue';
import BackgroundImage from '@/Shared/Fields/BackgroundImage.vue';
import BackgroundImageLibrary from '@/Shared/Fields/BackgroundImageLibrary.vue';
import Textinput from '@/Shared/Fields/Textinput.vue';
import Selection from '@/Shared/Fields/Selection.vue';
import Color from '@/Shared/Fields/Color.vue';
import Display from '@/Shared/Fields/Display.vue';
import DisplayGroup from '@/Shared/Fields/DisplayGroup.vue';
import { SVG } from '@svgdotjs/svg.js';
import { splice } from 'lodash';

export default {
  metaInfo: function () {
    return { title: `Vorlage "${this.template.name}"` };
  },

  components: {
    NavbarSmallscreen,

    SubmitButton,
    PendingModal,
    Spinner,
    Toggle,
    BackgroundColor,
    BackgroundImage,
    BackgroundImageLibrary,
    Textinput,
    Selection,
    Color,
    Display,
    DisplayGroup,
  },

  props: {
    template: Object,
    svg: String,
    conf: Object,
  },

  created() {
    window.addEventListener('resize', this.resizeWindowHandler);
  },

  mounted() {
    // it seems as if it is not easy to give fixed elements
    // a width of 100% of their parent. so for now we are using this
    // javascript workaround (which sometime is not working)
    let w = document.querySelector('.template').parentNode.offsetWidth;
    console.log(w);
    document.querySelector('.template').style.width = `${w}px`;

    // SVG('#path319').fill('rgba(59,130,246,1)');
    // SVG('#path321').fill('rgba(224,0,52,1)');
    //   var draw = SVG().addTo('body').size(300, 300)
    //   var rect = draw.rect(100, 100).attr({ fill: '#f06' })
    //   var test = SVG('#test rect');
    //   test.fill('#000000');
    // console.log(document.head.querySelector('meta[name="csrf-token"]').content);
    // let svg = this.backgroundImages[0].svg;
    //         svg = svg.replace('%$%LINK%$%', 'dddd')
    //           .replace('%$%X%$%', 0)
    //           .replace('%$%Y%$%', 0)
    //           .replace('%$%WIDTH%$%', this.backgroundImages[0].width)
    //           .replace('%$%HEIGHT%$%', this.backgroundImages[0].height)
    //           .trim();
    //           console.log(svg);
    //         // SVG(`svg g.image0`).replace(svg);
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeWindowHandler);
  },

  data() {
    return {
      busy: false,
      svgBusy: false,
      activeSection: 'background-image',

      form: this.$inertia.form({
        backgroundImageStatus: [],
        backgroundColors: {},
        backgroundColorsStatus: {},
        texts: {},
        textsStatus: {},
        textsColors: {},
        selects: {},
        selectsStatus: {},
        selectsColors: {},
        extraColors: {},
        extraColorStatus: {},
        extraDisplayStatus: {},
      }),
    };
  },

  watch: {
    // form: {
    //   handler() {
    //     for (let i = 0; i < this.form.texts.length; i++) {
    //       // jquery original
    //       // $('svg .text' + text + ' text tspan')
    //       SVG(`svg .text${i} text tspan`).text(this.form.texts[i]);
    //     }
    //   },
    //   deep: true,
    // },
  },

  methods: {
    resizeWindowHandler(e) {
      let w = document.querySelector('.template').parentNode.offsetWidth;
      document.querySelector('.template').style.width = `${w}px`;
    },

    backgroundImageUploading(status) {
      this.svgBusy = status;
    },

    backgroundImageUploaded(image) {
      SVG(`#backgroundImage`)?.attr('href', image);
      SVG(`#backgroundImage`)?.removeClass('hidden');
    },

    backgroundImageSelected(image) {
      this.svgBusy = true;
      // console.log(route('social-graphics.background-image-library.link', { template: this.template, image: image }));
      SVG(`#backgroundImage`)?.attr(
        'href',
        this.route('social-graphics.background-image-library.link', {
          template: this.template,
          image: image,
        })
      );
      SVG(`#backgroundImage`)?.removeClass('hidden');
      this.svgBusy = false;
    },

    backgroundImageStatusToggled(status) {
      this.form.backgroundImageStatus = status;

      if (status) {
        SVG('#backgroundImage')?.removeClass('hidden');
      } else {
        SVG('#backgroundImage')?.addClass('hidden');
      }
    },

    backgroundColorSelected(element, color) {
      this.$set(this.form.backgroundColors, element.id, color);

      SVG(`#${element.id}`)?.fill(`rgba(${color},1)`);
    },

    backgroundColorStatusToggled(element, status) {
      this.$set(this.form.backgroundColorsStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.removeClass('hidden');
      } else {
        SVG(`#${element.id}`)?.addClass('hidden');
      }
    },

    textEntered(element, text) {
      // this.form.textsColors.splice(element.id, 1, text);
      this.$set(this.form.texts, element.id, text);

      // 'div' is for text that is placed on top of growing
      // background shapes
      if (element.child == 'div') {
        document.getElementById(element.id).innerHTML = text;
      
      // tspan if we have multiple tspan
      } else if(element.child == 'tspan') {
        document.getElementById(element.id).innerHTML = text;

      } else {
        SVG(`#${element.id}`)?.find('tspan')?.text(text);
      }
    },

    textColorChanged(element, color) {
      this.$set(this.form.textsColors, element.id, color);

      // 'div' is for text that is placed on top of growing
      // background shapes
      if (element.child == 'div') {
        document.getElementById(element.id).style.color = `rgba(${color},1)`;
      } else {
        SVG(`#${element.id}`)?.fill(`rgba(${color},1)`);
      }
    },

    textStatusToggled(element, status) {
      this.$set(this.form.textsStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },

    optionSelected(element, option) {
      this.$set(this.form.selects, element.id, option);

      SVG(`#${element.id}`)?.find('tspan')?.text(option);
    },

    selectColorChanged(element, color) {
      this.$set(this.form.selectsColors, element.id, color);

      SVG(`#${element.id}`)?.fill(`rgba(${color},1)`);
    },

    selectStatusToggled(element, status) {
      this.$set(this.form.selectsStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },

    extraColorChanged(element, color) {
      this.$set(this.form.extraColors, element.id, color);

      let opacity = 1;
      if(element.opacity) {
        opacity = element.opacity;
      }

      SVG(`#${element.id}`)?.fill(`rgba(${color},${opacity})`);
    },

    extraColorStatusToggled(element, status) {
      this.$set(this.form.extraColorStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },

    extraDisplayStatusToggled(element, status) {
      // console.log(element.id);
      // console.log(status);
      this.$set(this.form.extraDisplayStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },

    store() {
      let hiddenElements = SVG('div.template svg > .hidden');

      if (hiddenElements !== null) {
        SVG('div.template svg > .hidden').remove();
      }

      let svg = SVG('div.template svg').svg();
      // svg = svg.find('.hidden').remove();
      // svg = svg.svg();

      // svg.removeClass('hidden');

      this.busy = true;

      // window.axios
      //   .post(
      //     this.route('social-graphics.store'), {
      //       template: this.template.id,
      //     }
      //   )
      //   .then(() => {
      //     this.busy = false;
      //   })
      //   .catch((error) => {});

      // this.$inertia
      //   .post(
      //     this.route('social-graphics.store', {
      //       template: this.template.id,
      //     }),
      //     {
      //       svg: svg,
      //       texts: this.form.texts,
      //     },
      //     {
      //       preserveScroll: true,
      //     }
      //   )
      //   .then(() => {
      //     this.busy = false;
      //   });


      this.$inertia
        .post(
          route('social-graphics.store', {
            template: this.template.id,
          }),
          {
            svg: svg,
            texts: this.form.texts,
          },
          {
            preserveScroll: true,
            onSuccess: () => {
              this.busy = false;
            }
          }
        );
    },
  },

  computed: {
    backgroundImage() {
      return this.conf.backgroundImage || null;
    },

    hasBackgroundImage() {
      return this.backgroundImage !== null;
    },

    usesBackgroundImageLibrary() {
      return this.conf.backgroundImage.library || false;
    },

    backgroundColors() {
      // return this.conf.backgroundColors[0].colors || [];
      return this.conf.backgroundColors || [];
    },

    hasBackgroundColors() {
      return this.backgroundColors.length > 0;
    },

    texts() {
      return this.conf.texts || [];
    },

    hasTexts() {
      return this.texts.length > 0;
    },

    extras() {
      return this.conf.extras || [];
    },

    hasExtras() {
      return this.extras.length > 0;
    },
  },
};
</script>

<style>
div.template svg {
  width: 100% !important;
  height: 100% !important;
}

/* .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
} */
</style>
