import './bootstrap';

import '../css/app.css'


import Vue from 'vue'
// import { createInertiaApp } from '@inertiajs/inertia-vue'
import { createInertiaApp } from '@inertiajs/vue2'
// import { InertiaProgress } from '@inertiajs/progress'




Vue.mixin({
    methods: {
        route: route
    }
});


import 'font-awesome/css/font-awesome.min.css';


// TODO: create a custom tooltip directive or maybe find a better one
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

Vue.use(Tooltip, {
    placement: 'body',
    class: 'tooltip-custom',
});


import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})


import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


import Multiselect from 'vue-multiselect';
// Vue.use(Multiselect)
Vue.component('multiselect', Multiselect)


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);


import { VueMasonryPlugin } from 'vue-masonry';
Vue.use(VueMasonryPlugin);
// import 'masonry-layout/dist/masonry.pkgd.min.js';


import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)




import HorizontalDivider from '../views/Shared/HorizontalDivider.vue';
Vue.component('horizontal-divider', HorizontalDivider);

// import { Link } from '@inertiajs/inertia-vue';


import { Link } from '@inertiajs/vue2'
Vue.component('Link', Link);



Vue.prototype.$filters = {
    formatDateTime: date => {
        if (!date) return ''

        var d = new Date(date);

        return d.toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        }) + ' Uhr';

        // return `${d.getDate()}.${d.getFullMonth() + 1}.${d.getFullYear()}`; 
    },

    formatDate: date => {
        if (!date) return ''

        var d = new Date(date);
        return d.toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        // return `${d.getDate()}.${d.getFullMonth() + 1}.${d.getFullYear()}`; 
    },

    formatTime: time => {
        if (!time) return ''

        var t = new Date(time);
        return t.toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit'
        });
    },


    formatFilesize: filesize => {
        if (!filesize) return '';

        if (filesize >= 1000000) {
            let size = (filesize / 1000000).toFixed(1);
            return `${size} MB`;
        }

        let size = (filesize / 1000).toFixed(0);
        return `${size} KB`;
    },





}





const pages = import.meta.glob('../views/Pages/**/*.vue');
// console.log(pages); 

createInertiaApp({
    //   resolve: name => require(`./Pages/${name}`),
    // resolveComponent: async (name) => {
    //     return (await import(`./Pages/${name}.vue`)).default;
    // },
    // resolve: async (name) => {
    //     const pages = import.meta.glob('./Pages/**/*.vue');
    //     console.log(pages);
    //     alert("ddd");

    //     return (await pages[`./Pages/${name}.vue`]()).default;
    // },
    resolve: name => {
        const importPage = pages[`../views/Pages/${name}.vue`];

        if (!importPage) {
            throw new Error(`Unknown page ${name}. Is it located under Pages with a .vue extension?`);
        }

        return importPage().then(module => module.default)
    },
    setup({ el, App, props, plugin }) {
        Vue.use(plugin)

        new Vue({
            metaInfo: {
                titleTemplate: (title) => title ? `${title} - Social Graphics` : 'Social Graphics'
            },
            render: h => h(App, props),
        }).$mount(el)
    },

    progress: {
        color: '#dc2618',
    },
})

// const pages = import.meta.glob('../views/pages/**/*.vue');

// const inertiaApp = createInertiaApp({
//     resolve: name => {
//         const importPage = pages[`../views/pages/${name}.vue`];

//         if (!importPage) {
//             throw new Error(`Unknown page ${name}. Is it located under Pages with a .vue extension?`);
//         }

//         return importPage().then(module => module.default)
//     },
//     setup({el, App, props}) {
//         new Vue({
//             render: h => h(App, props),
//         }).$mount(el)
//     },
// });