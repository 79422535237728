
<div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
  <div class="max-w-lg w-full lg:max-w-xs">
    <div class="relative">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <input
      type="search"
        v-model="search"
        class="mt-1 pl-10 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder="Suchen nach..."
      />

      <div
        v-show="isOpen"
        v-click-outside="close"
        class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
      >
        <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <div class="py-1">
            <Link
              v-for="(result, idx) in results"
              :key="idx"
              :href="route('social-graphics.edit', { id: result.id })"
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            >
              {{ result.word }}
              
            </Link>


            <Link
              v-if="!results.length"
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              v-html="`<strong>${search}</strong> neu anlegen`"
            >
              
            </Link>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
