<template>
  <layout>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div
          class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <!-- Page Header: start -->
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <h2
                class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate"
              >
                Vorlage &bdquo;{{ form.name }}&ldquo;
              </h2>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <span class="sm:ml-3 shadow-sm rounded-md">
                <Link
                  :href="route('templates.index')"
                  preserve-scroll
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                  >Übersicht</Link
                >
              </span>
            </div>
          </div>
          <!-- Page Header: end -->

          <horizontal-divider />

          <form @submit.prevent="submit" novalidate>
            <div>
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Hauptangaben
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                  </div>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-5 bg-white sm:p-6">
                      <div class="grid grid-cols-3 gap-6">
                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                            >Partner</label
                          >
                          <select
                            v-model="form.partner_id"
                            :disabled="form.processing"
                            @change="form.partner_id = $event.target.value"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                          >
                            <option value="">-- bitte wählen --</option>
                            <option
                              v-for="partner in partners"
                              :value="partner.id"
                              :key="partner.id"
                              :selected="partner.id == form.partner_id"
                            >
                              {{ partner.name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                            >Vorlagen-Art</label
                          >
                          <select
                            v-model="form.template_type_id"
                            :disabled="form.processing"
                            @change="
                              form.template_type_id = $event.target.value
                            "
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                          >
                            <option value="">-- bitte wählen --</option>
                            <option
                              v-for="templateType in templateTypes"
                              :value="templateType.id"
                              :key="templateType.id"
                              :selected="
                                templateType.id == form.template_type_id
                              "
                            >
                              {{ templateType.name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                            >Name</label
                          >
                          <input
                            type="text"
                            id="name"
                            v-model="form.name"
                            :disabled="form.processing"
                            class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div class="mt-6">
                        <label
                          for="note"
                          class="block text-sm leading-5 font-medium text-gray-700"
                          >Bemerkungen</label
                        >
                        <div class="mt-1">
                          <textarea
                            id="note"
                            v-model="form.note"
                            :disabled="form.processing"
                            rows="5"
                            class="shadow-sm focus:ring-gray-400 focus:border-gray-400 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <horizontal-divider />

            <div>
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Vorlagen-Status

                      <i
                          class="fa fa-fw fa-circle ml-2"
                          :class="template.passes_tests ? 'text-brandhelfer-green' : 'text-brandhelfer-red'"
                        ></i>
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                  </div>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-5 bg-white sm:p-6">

                      <div class="text-sm font-medium text-gray-700 mb-2">
                        Konfigurationsdatei vorhanden
                        <i
                          class="fa fa-fw fa-lg"
                          :class="template.has_configuration_file ? 'fa-check text-brandhelfer-green' : 'fa-times text-brandhelfer-red'"
                        ></i>
                      </div>

                      <div class="text-sm font-medium text-gray-700 mb-2">
                        Konfigurationsdatei mit korrekter Syntax
                        <i
                          class="fa fa-fw fa-lg"
                          :class="template.has_correct_syntax_configuration_file ? 'fa-check text-brandhelfer-green' : 'fa-times text-brandhelfer-red'"
                        ></i>
                      </div>

                      <div class="text-sm font-medium text-gray-700 mb-2">
                        SVG-Vorlagendatei vorhanden
                        <i
                          class="fa fa-fw fa-lg"
                          :class="template.has_svg_template ? 'fa-check text-brandhelfer-green' : 'fa-times text-brandhelfer-red'"
                        ></i>
                      </div>

                      <div class="text-sm font-medium text-gray-700">
                        Vorschaubild vorhanden
                        <i
                          class="fa fa-fw fa-lg"
                          :class="template.has_preview_image ? 'fa-check text-brandhelfer-green' : 'fa-times text-brandhelfer-red'"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <horizontal-divider />

            <div>
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      User mit Zugriffsrecht
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                  </div>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-5 bg-white sm:p-6">
                      <div
                        v-if="!assignedUsers.length"
                        class="text-sm font-medium text-gray-700"
                      >
                        ---
                      </div>

                      <div
                        class="text-sm font-medium text-gray-700"
                        v-for="assignedUser in assignedUsers"
                        :key="assignedUser.id"
                      >
                        {{ assignedUser.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-8 border-t border-gray-200 pt-5">
              <div class="flex justify-end">
                <submit-button
                  :sending="form.processing"
                  :validated="validated"
                  :full-width="false"
                  >Speichern</submit-button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue';

import SubmitButton from '@/Shared/SubmitButton.vue';

export default {
  metaInfo: { title: 'Vorlage bearbeiten' },

  components: {
    Layout,
    
    SubmitButton,
  },

  props: {
    template: Object,
    partners: Array,
    templateTypes: Array,
    assignedUsers: Array,
  },

  data() {
    return {
      form: this.$inertia.form({
        template_type_id: this.template.template_type_id,
        name: this.template.name,
        partner_id: this.template.partner_id,
        note: this.template.note,
      }),
    };
  },

  methods: {
    submit() {
      this.form.post(route('templates.update', this.template.id), {}, {
        preserveScroll: true,
      });
    },
  },

  computed: {
    validated() {
      return (
        this.form.template_type_id &&
        this.form.name.length &&
        this.form.partner_id
      );
    },
  },
};
</script>
