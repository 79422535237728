<template>
  <layout>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div
          class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <!-- Page Header: start -->
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                <h2
                  class="ml-2 mt-2 text-2xl leading-7 font-light text-gray-900"
                >
                  Social Graphics
                </h2>
                <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
                  Vorlage &quot;{{ template.name }}&quot;
                </p>
              </div>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <span class="sm:ml-3 shadow-sm rounded-md">
                <Link
                  :href="route('social-graphics.index')"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-500 hover:text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-400 active:bg-gray-400 transition duration-150 ease-in-out"
                  >Übersicht</Link
                >
              </span>
            </div>
          </div>
          <!-- Page Header: end -->

          <horizontal-divider />

          <!-- <form @submit.prevent="submit" novalidate> -->
          <form novalidate>
            <div>
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1 px-4 sm:px-0 relative">
                  <spinner :busy="svgBusy"></spinner>
                  <div class="template shadow-md fixed w-min" v-html="svg"></div>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <button
                    :disabled="busy"
                    @click="store"
                    class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green"
                  >
                    Speichern
                  </button>

                  <horizontal-divider />

                  <div class="flex flex-row">
                    <div class="w-1/5">
                      <div v-if="hasBackgroundImage">
                        <i
                          @click="activeSection = 'background-image'"
                          class="fa fa-fw fa-lg fa-image mb-4 cursor-pointer hover:text-brandhelfer-green"
                          :class="
                            activeSection == 'background-image'
                              ? 'text-brandhelfer-green'
                              : ''
                          "
                        ></i>
                      </div>

                      <div v-if="hasBackgroundColors">
                        <i
                          @click="activeSection = 'background-color'"
                          class="fa fa-fw fa-lg fa-paint-brush mb-4 cursor-pointer hover:text-brandhelfer-green"
                          :class="
                            activeSection == 'background-color'
                              ? 'text-brandhelfer-green'
                              : ''
                          "
                        ></i>
                      </div>

                      <div v-if="hasLayers">
                        <i
                          @click="activeSection = 'layer'"
                          class="fa fa-fw fa-lg fa-object-ungroup mb-4 cursor-pointer hover:text-brandhelfer-green"
                          :class="
                            activeSection == 'layer'
                              ? 'text-brandhelfer-green'
                              : ''
                          "
                        ></i>
                      </div>

                      <div v-if="hasTexts">
                        <i
                          @click="activeSection = 'text'"
                          class="fa fa-fw fa-lg fa-font mb-4 cursor-pointer hover:text-brandhelfer-green"
                          :class="
                            activeSection == 'text'
                              ? 'text-brandhelfer-green'
                              : ''
                          "
                        ></i>
                      </div>

                      <div v-if="hasExtras">
                        <i
                          @click="activeSection = 'extras'"
                          class="fa fa-fw fa-lg fa-th-large mb-4 cursor-pointer hover:text-brandhelfer-green"
                          :class="
                            activeSection == 'extras'
                              ? 'text-brandhelfer-green'
                              : ''
                          "
                        ></i>
                      </div>
                    </div>

                    <div class="flex-grow">
                      <!-- Background Image -->
                      <div
                        v-show="
                          hasBackgroundImage &&
                          activeSection == 'background-image'
                        "
                        class="flex flex-row w-full"
                      >
                        <background-image
                          :backgroundImage="backgroundImage"
                          @imageUploading="backgroundImageUploading"
                          @imageUploaded="backgroundImageUploaded"
                          @statusToggled="backgroundImageStatusToggled"
                        >
                        </background-image>
                      </div>

                      <div class="flex-grow">
                        <!-- Background Image -->
                        <div
                          v-show="
                            hasBackgroundImage &&
                            usesBackgroundImageLibrary &&
                            activeSection == 'background-image'
                          "
                          class="flex flex-row w-full"
                        >
                          <background-image-library
                            :template="template"
                            @imageSelected="backgroundImageSelected"
                          >
                          </background-image-library>
                        </div>
                      </div>


                      <!-- Layer -->
                      <transition name="fade">
                        <div
                          v-show="
                            hasLayers &&
                            activeSection == 'layer'
                          "
                        >
                          <div
                            class="flex flex-row w-full mb-4"
                            v-for="layer in layers"
                            :key="layer.id"
                          >
                            <layer
                              :template="template"
                              :layer="layer"
                              @layerSelected="layerSelected"
                              @statusToggled="layerStatusToggled"
                              :enabled="form.layerStatus[layer.id] || false"
                            ></layer>
                          </div>
                        </div>
                      </transition>


                      <!-- Background Color -->
                      <transition name="fade">
                        <div
                          v-show="
                            hasBackgroundColors &&
                            activeSection == 'background-color'
                          "
                        >
                          <div
                            class="flex flex-row w-full mb-4"
                            v-for="backgroundColor in backgroundColors"
                            :key="backgroundColor.id"
                          >
                            <background-color
                              :backgroundColor="backgroundColor"
                              @colorSelected="backgroundColorSelected"
                              @colorStatusToggled="backgroundColorStatusToggled"
                            ></background-color>
                          </div>
                        </div>
                      </transition>

                      <!-- Text -->
                      <transition name="fade">
                        <div
                          v-show="hasTexts && activeSection == 'text'"
                          class="w-full"
                        >
                          <div>Texte</div>

                          <div class="mt-6">
                            <div
                              v-for="(text, idx) in texts"
                              :key="`text${idx}`"
                              class="flex flex-row w-full mb-6"
                            >
                              <textinput
                                v-if="text.type == 'textinput'"
                                :text="text"
                                @textEntered="textEntered"
                                @textColorChanged="textColorChanged"
                                @textStatusToggled="textStatusToggled"
                              ></textinput>

                              <selection
                                v-if="text.type == 'selection'"
                                :select="text"
                                @optionSelected="optionSelected"
                                @selectColorChanged="selectColorChanged"
                                @selectStatusToggled="selectStatusToggled"
                              ></selection>
                            </div>
                          </div>
                        </div>
                      </transition>

                      <!-- Extras -->
                      <transition name="fade">
                        <div
                          v-show="hasExtras && activeSection == 'extras'"
                          class="w-full"
                        >
                          <div class="mt-6">
                            <div
                              v-for="(extra, idx) in extras"
                              :key="`extra${idx}`"
                              class="flex flex-row w-full mb-6"
                            >
                              <color
                                v-if="extra.type == 'color'"
                                :color="extra"
                                @colorChanged="extraColorChanged"
                                @statusToggled="extraColorStatusToggled"
                              ></color>

                              <display
                                v-if="extra.type == 'display'"
                                :display="extra"
                                @statusToggled="extraDisplayStatusToggled"
                              ></display>

                              <display-group
                                v-if="extra.type == 'displayGroup'"
                                :display="extra"
                                @statusToggled="extraDisplayStatusToggled"
                              ></display-group>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <pending-modal v-if="busy"></pending-modal>
  </layout> 
</template>

<script>
import Layout from '@/Shared/Layout.vue';

import SubmitButton from '@/Shared/SubmitButton.vue';
import PendingModal from '@/Shared/PendingModal.vue';
import Spinner from '@/Shared/Spinner.vue';
import Toggle from '@/Shared/Toggle.vue';
import BackgroundColor from '@/Shared/Fields/BackgroundColor.vue';
import BackgroundImage from '@/Shared/Fields/BackgroundImage.vue';
import BackgroundImageLibrary from '@/Shared/Fields/BackgroundImageLibrary.vue';
import Layer from '@/Shared/Fields/Layer.vue';
import Textinput from '@/Shared/Fields/Textinput.vue';
import Selection from '@/Shared/Fields/Selection.vue';
import Color from '@/Shared/Fields/Color.vue';
import Display from '@/Shared/Fields/Display.vue';
import DisplayGroup from '@/Shared/Fields/DisplayGroup.vue';
import { SVG } from '@svgdotjs/svg.js';
import { splice } from 'lodash';

export default {
  metaInfo: function () {
    return { title: `Vorlage "${this.template.name}"` };
  },

  components: {
    Layout,
    
    SubmitButton,
    PendingModal,
    Spinner,
    Toggle,
    BackgroundColor,
    BackgroundImage,
    BackgroundImageLibrary,
    Layer,
    Textinput,
    Selection,
    Color,
    Display,
    DisplayGroup,
  },

  props: {
    template: Object,
    svg: String,
    conf: Object,
  },

  created() {
    window.addEventListener('resize', this.resizeWindowHandler);
  },

  mounted() {
    


    // it seems as if it is not easy to give fixed elements
    // a width of 100% of their parent. so for now we are using this
    // javascript workaround (which sometime is not working)
    window.focus();
    let w = 0;
    setTimeout(function() {
      w = document.querySelector('.template').parentNode.offsetWidth;
      console.log(w);
      document.querySelector('.template').style.width = `${w}px`;
    }, 500);
    // 
    

    // SVG('#path319').fill('rgba(59,130,246,1)');
    // SVG('#path321').fill('rgba(224,0,52,1)');
    //   var draw = SVG().addTo('body').size(300, 300)
    //   var rect = draw.rect(100, 100).attr({ fill: '#f06' })
    //   var test = SVG('#test rect');
    //   test.fill('#000000');
    // console.log(document.head.querySelector('meta[name="csrf-token"]').content);
    // let svg = this.backgroundImages[0].svg;
    //         svg = svg.replace('%$%LINK%$%', 'dddd')
    //           .replace('%$%X%$%', 0)
    //           .replace('%$%Y%$%', 0)
    //           .replace('%$%WIDTH%$%', this.backgroundImages[0].width)
    //           .replace('%$%HEIGHT%$%', this.backgroundImages[0].height)
    //           .trim();
    //           console.log(svg);
    //         // SVG(`svg g.image0`).replace(svg);
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeWindowHandler);
  },
  

  data() {
    return {
      busy: false,
      svgBusy: false,
      activeSection: 'background-image',

      // form: this.$inertia.form({
      form: {
        backgroundImageStatus: [],
        backgroundColors: {},
        backgroundColorsStatus: {},
        texts: {},
        textsStatus: {},
        textsColors: {},
        selects: {},
        selectsStatus: {},
        selectsColors: {},
        extraColors: {},
        extraColorStatus: {},
        extraDisplayStatus: {},
        layerStatus: {},
      },
    };
  },

  watch: {
    // form: {
    //   handler() {
    //     for (let i = 0; i < this.form.texts.length; i++) {
    //       // jquery original
    //       // $('svg .text' + text + ' text tspan')
    //       SVG(`svg .text${i} text tspan`).text(this.form.texts[i]);
    //     }
    //   },
    //   deep: true,
    // },
  },

  methods: {
    resizeWindowHandler(e) {
      let w = document.querySelector('.template').parentNode.offsetWidth;
      document.querySelector('.template').style.width = `${w}px`;
    },

    backgroundImageUploading(status) {
      this.svgBusy = status;
    },

    backgroundImageUploaded(image) {
      SVG(`#backgroundImage`)?.attr('href', image);
      SVG(`#backgroundImage`)?.removeClass('hidden');
    },

    backgroundImageSelected(image) {
      this.svgBusy = true;
      // console.log(route('social-graphics.background-image-library.link', { template: this.template, image: image }));
      SVG(`#backgroundImage`)?.attr(
        'href',
        route('social-graphics.background-image-library.link', {
          template: this.template,
          image: image,
        })
      );
      SVG(`#backgroundImage`)?.removeClass('hidden');
      this.svgBusy = false;
    },

    backgroundImageStatusToggled(status) {
      this.form.backgroundImageStatus = status;

      if (status) {
        SVG('#backgroundImage')?.removeClass('hidden');
      } else {
        SVG('#backgroundImage')?.addClass('hidden');
      }
    },

    backgroundColorSelected(element, color) {
      this.$set(this.form.backgroundColors, element.id, color);

      SVG(`#${element.id}`)?.fill(`rgba(${color},1)`);
    },

    backgroundColorStatusToggled(element, status) {
      this.$set(this.form.backgroundColorsStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.removeClass('hidden');
      } else {
        SVG(`#${element.id}`)?.addClass('hidden');
      }
    },

    textEntered(element, text) {
      
      // 'div' is for text that is placed on top of growing 
      // background shapes
      if(element.child == 'div') {
        document.getElementById(element.id).innerHTML = text;      
      
      // tspan if we have multiple tspan
      } else if(element.child == 'tspan') {
        document.getElementById(element.id).innerHTML = text;

      } else {
        SVG(`#${element.id}`)?.find('tspan')?.text(text);
      }      
    },

    textColorChanged(element, color) {
      this.$set(this.form.textsColors, element.id, color);

      // 'div' is for text that is placed on top of growing 
      // background shapes
      if(element.child == 'div') {        
        document.getElementById(element.id).style.color = `rgba(${color},1)`;
      } else {
        SVG(`#${element.id}`)?.fill(`rgba(${color},1)`);
      }
    },

    textStatusToggled(element, status) {
      this.$set(this.form.textsStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },

    optionSelected(element, option) {
      this.$set(this.form.selects, element.id, option);

      SVG(`#${element.id}`)?.find('tspan')?.text(option);
    },

    selectColorChanged(element, color) {
      this.$set(this.form.selectsColors, element.id, color);

      SVG(`#${element.id}`)?.fill(`rgba(${color},1)`);
    },

    selectStatusToggled(element, status) {
      this.$set(this.form.selectsStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },

    extraColorChanged(element, color) {
      this.$set(this.form.extraColors, element.id, color);

      let opacity = 1;
      if(element.opacity) {
        opacity = element.opacity;
      }

      SVG(`#${element.id}`)?.fill(`rgba(${color},${opacity})`);
    },

    extraColorStatusToggled(element, status) {
      this.$set(this.form.extraColorStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },

    extraDisplayStatusToggled(element, status) {
      // console.log(element.id);
      // console.log(status);
      this.$set(this.form.extraDisplayStatus, element.id, status);

      if (status) {
        SVG(`#${element.id}`)?.show();
      } else {
        SVG(`#${element.id}`)?.hide();
      }
    },


    layerSelected(layer, layerItem) {
      this.svgBusy = true;

      console.log(layer);
      console.log(layerItem);
      // console.log(route('social-graphics.background-image-library.link', { template: this.template, image: image }));
      SVG(`#${layer}`)?.attr(
        'href',
        route('social-graphics.layers.image-link', {
          template: this.template,
          layer: layer,
          layerItem: layerItem
        })
      );
      SVG(`#${layer}`)?.removeClass('hidden');

      this.$set(this.form.layerStatus, layer, true);

      this.svgBusy = false;
    },

    layerStatusToggled(layer, status) {
      this.$set(this.form.layerStatus, layer.id, status);

      if (status) {
        SVG(`#${layer.id}`)?.removeClass('hidden');
      } else {
        SVG(`#${layer.id}`)?.addClass('hidden');
      }
    },

    store() {
      // let hiddenElements = SVG('div.template svg > .hidden');
      // let hiddenElements = SVG('div.template svg').find('.hidden');
      
      // console.log(hiddenElements);
      // return false;

      // if (hiddenElements !== null) {
      //   SVG('div.template svg > .hidden').remove();
      // }

      SVG('div.template svg').find('.hidden').remove();

      let svg = SVG('div.template svg').svg();
      // console.log(svg); return;
      // svg = svg.find('.hidden').remove();
      // svg = svg.svg();

      // svg.removeClass('hidden');

      this.busy = true;

      // window.axios
      //   .post(
      //     route('social-graphics.store'), {
      //       template: this.template.id,
      //     }
      //   )
      //   .then(() => {
      //     this.busy = false;
      //   })
      //   .catch((error) => {});

      this.$inertia
        .post(
          route('social-graphics.store', {
            template: this.template.id,
          }),
          {
            svg: svg,
            texts: this.form.texts,
          },
          {
            preserveScroll: true,
            onSuccess: () => {
              this.busy = false;
            }
          }
        );
    },
  },

  computed: {
    backgroundImage() {
      return this.conf.backgroundImage || null;
    },

    hasBackgroundImage() {
      return this.backgroundImage !== null;
    },

    usesBackgroundImageLibrary() {
      return this.conf.backgroundImage.library || false;
    },

    layers() {
      return this.conf.layers || [];
    },

    hasLayers() {
      return this.layers.length > 0;
    },

    backgroundColors() {
      // return this.conf.backgroundColors[0].colors || [];
      return this.conf.backgroundColors || [];
    },

    hasBackgroundColors() {
      return this.backgroundColors.length > 0;
    },

    texts() {
      return this.conf.texts || [];
    },

    hasTexts() {
      return this.texts.length > 0;
    },

    extras() {
      return this.conf.extras || [];
    },

    hasExtras() {
      return this.extras.length > 0;
    },
  },
};
</script>

<style>
div.template svg {
  width: 100% !important;
  height: 100% !important;
}

/* .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
} */
</style>
