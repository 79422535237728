
<layout>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div
        class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      >
        <!-- Page Header: start -->
        <div class="md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
              <h2
                class="ml-2 mt-2 text-2xl leading-7 font-light text-gray-900"
              >
                Social Graphics
              </h2>
              <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
                Vorlage &quot;{{ template.name }}&quot;
              </p>
            </div>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <span class="sm:ml-3 shadow-sm rounded-md">
              <Link
                :href="route('social-graphics.index')"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-500 hover:text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-400 active:bg-gray-400 transition duration-150 ease-in-out"
                >Übersicht</Link
              >
            </span>
          </div>
        </div>
        <!-- Page Header: end -->

        <horizontal-divider />

        <!-- <form @submit.prevent="submit" novalidate> -->
        <form novalidate>
          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1 px-4 sm:px-0 relative">
                <spinner :busy="svgBusy"></spinner>
                <div class="template shadow-md fixed w-min" v-html="svg"></div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <button
                  :disabled="busy"
                  @click="store"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green"
                >
                  Speichern
                </button>

                <horizontal-divider />

                <div class="flex flex-row">
                  <div class="w-1/5">
                    <div v-if="hasBackgroundImage">
                      <i
                        @click="activeSection = 'background-image'"
                        class="fa fa-fw fa-lg fa-image mb-4 cursor-pointer hover:text-brandhelfer-green"
                        :class="
                          activeSection == 'background-image'
                            ? 'text-brandhelfer-green'
                            : ''
                        "
                      ></i>
                    </div>

                    <div v-if="hasBackgroundColors">
                      <i
                        @click="activeSection = 'background-color'"
                        class="fa fa-fw fa-lg fa-paint-brush mb-4 cursor-pointer hover:text-brandhelfer-green"
                        :class="
                          activeSection == 'background-color'
                            ? 'text-brandhelfer-green'
                            : ''
                        "
                      ></i>
                    </div>

                    <div v-if="hasLayers">
                      <i
                        @click="activeSection = 'layer'"
                        class="fa fa-fw fa-lg fa-object-ungroup mb-4 cursor-pointer hover:text-brandhelfer-green"
                        :class="
                          activeSection == 'layer'
                            ? 'text-brandhelfer-green'
                            : ''
                        "
                      ></i>
                    </div>

                    <div v-if="hasTexts">
                      <i
                        @click="activeSection = 'text'"
                        class="fa fa-fw fa-lg fa-font mb-4 cursor-pointer hover:text-brandhelfer-green"
                        :class="
                          activeSection == 'text'
                            ? 'text-brandhelfer-green'
                            : ''
                        "
                      ></i>
                    </div>

                    <div v-if="hasExtras">
                      <i
                        @click="activeSection = 'extras'"
                        class="fa fa-fw fa-lg fa-th-large mb-4 cursor-pointer hover:text-brandhelfer-green"
                        :class="
                          activeSection == 'extras'
                            ? 'text-brandhelfer-green'
                            : ''
                        "
                      ></i>
                    </div>
                  </div>

                  <div class="flex-grow">
                    <!-- Background Image -->
                    <div
                      v-show="
                        hasBackgroundImage &&
                        activeSection == 'background-image'
                      "
                      class="flex flex-row w-full"
                    >
                      <background-image
                        :backgroundImage="backgroundImage"
                        @imageUploading="backgroundImageUploading"
                        @imageUploaded="backgroundImageUploaded"
                        @statusToggled="backgroundImageStatusToggled"
                      >
                      </background-image>
                    </div>

                    <div class="flex-grow">
                      <!-- Background Image -->
                      <div
                        v-show="
                          hasBackgroundImage &&
                          usesBackgroundImageLibrary &&
                          activeSection == 'background-image'
                        "
                        class="flex flex-row w-full"
                      >
                        <background-image-library
                          :template="template"
                          @imageSelected="backgroundImageSelected"
                        >
                        </background-image-library>
                      </div>
                    </div>


                    <!-- Layer -->
                    <transition name="fade">
                      <div
                        v-show="
                          hasLayers &&
                          activeSection == 'layer'
                        "
                      >
                        <div
                          class="flex flex-row w-full mb-4"
                          v-for="layer in layers"
                          :key="layer.id"
                        >
                          <layer
                            :template="template"
                            :layer="layer"
                            @layerSelected="layerSelected"
                            @statusToggled="layerStatusToggled"
                            :enabled="form.layerStatus[layer.id] || false"
                          ></layer>
                        </div>
                      </div>
                    </transition>


                    <!-- Background Color -->
                    <transition name="fade">
                      <div
                        v-show="
                          hasBackgroundColors &&
                          activeSection == 'background-color'
                        "
                      >
                        <div
                          class="flex flex-row w-full mb-4"
                          v-for="backgroundColor in backgroundColors"
                          :key="backgroundColor.id"
                        >
                          <background-color
                            :backgroundColor="backgroundColor"
                            @colorSelected="backgroundColorSelected"
                            @colorStatusToggled="backgroundColorStatusToggled"
                          ></background-color>
                        </div>
                      </div>
                    </transition>

                    <!-- Text -->
                    <transition name="fade">
                      <div
                        v-show="hasTexts && activeSection == 'text'"
                        class="w-full"
                      >
                        <div>Texte</div>

                        <div class="mt-6">
                          <div
                            v-for="(text, idx) in texts"
                            :key="`text${idx}`"
                            class="flex flex-row w-full mb-6"
                          >
                            <textinput
                              v-if="text.type == 'textinput'"
                              :text="text"
                              @textEntered="textEntered"
                              @textColorChanged="textColorChanged"
                              @textStatusToggled="textStatusToggled"
                            ></textinput>

                            <selection
                              v-if="text.type == 'selection'"
                              :select="text"
                              @optionSelected="optionSelected"
                              @selectColorChanged="selectColorChanged"
                              @selectStatusToggled="selectStatusToggled"
                            ></selection>
                          </div>
                        </div>
                      </div>
                    </transition>

                    <!-- Extras -->
                    <transition name="fade">
                      <div
                        v-show="hasExtras && activeSection == 'extras'"
                        class="w-full"
                      >
                        <div class="mt-6">
                          <div
                            v-for="(extra, idx) in extras"
                            :key="`extra${idx}`"
                            class="flex flex-row w-full mb-6"
                          >
                            <color
                              v-if="extra.type == 'color'"
                              :color="extra"
                              @colorChanged="extraColorChanged"
                              @statusToggled="extraColorStatusToggled"
                            ></color>

                            <display
                              v-if="extra.type == 'display'"
                              :display="extra"
                              @statusToggled="extraDisplayStatusToggled"
                            ></display>

                            <display-group
                              v-if="extra.type == 'displayGroup'"
                              :display="extra"
                              @statusToggled="extraDisplayStatusToggled"
                            ></display-group>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <pending-modal v-if="busy"></pending-modal>
</layout> 
