// import _ from 'lodash';
// window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.timeout = 10000;

window.axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
        let errorResponse = error.response;
        // console.log(errorResponse);
        if (errorResponse && errorResponse.status === 401) {
            //    && errorResponse.config && !errorResponse.config.__isRetryRequest
            window.location.href = '/login';
        }
    }
  );


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;
// window.Pusher.logToConsole = import.meta.env.VITE_PUSHER_APP_DEBUG;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],

//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     encrypted: import.meta.env.VITE_PUSHER_APP_ENCRYPTED
// });
