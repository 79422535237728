
<layout>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div
        class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      >
        <!-- Page Header: start -->
        <div class="md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <h2
              class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate"
            >
              Vorlagen
            </h2>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <span class="sm:ml-3 shadow-sm rounded-md">
              <Link
                :href="route('templates.create')"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >Neue Vorlage</Link
              >
            </span>
          </div>
        </div>
        <!-- Page Header: end -->

        <horizontal-divider />

        <!-- Filter: start -->
        <div class="flex flex-col sm:flex-row mb-4">
          <div class="mr-2 sm:w-5/12">
            <div class="mt-1 relative rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  class="h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="search"
                class="mt-1 pl-10 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Suchen nach..."
                v-model="form.search"
              />
            </div>
          </div>

          <div class="sm:w-7/12 flex">
            <div
              class="w-5/12 sm:w-11/12 mr-2"
            >
              <select
                v-model="form.template_type_id"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
              >
                <option :value="null">Alle Vorlagen-Arten</option>
                <option
                  v-for="templateType in templateTypes"
                  :value="templateType.id"
                  :key="templateType.id"
                >
                  {{ templateType.name }}
                </option>
              </select>
            </div>

            <div
              class="w-5/12 sm:w-11/12 mr-2"
            >
              <select
                v-model="form.partner_id"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
              >
                <option :value="null">Alle Partner</option>
                <option
                  v-for="partner in partners"
                  :value="partner.id"
                  :key="partner.id"
                >
                  {{ partner.name }}
                </option>
              </select>
            </div>

            <div class="w-2/12 sm:w-1/12 text-center mt-3">
              <button
                v-if="isFiltered"
                v-tooltip.left="'Filter zurücksetzen'"
                @click="reset"
              >
                <i class="fa fa-times fa-fw text-gray-500"></i>
              </button>

              <i
                v-if="!isFiltered"
                class="fa fa-times fa-fw text-gray-200"
              ></i>
            </div>
          </div>
        </div>
        <!-- Filter: end -->

        <!-- List: start -->
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
          <ul>
            <li
              v-for="(template, idx) in templates"
              :key="template.id"
              :class="idx ? 'border-t border-gray-200' : ''"
            >
              <a
                :href="route('templates.edit', { id: template.id })"
                class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
              >
                <div class="flex items-center px-4 py-4 sm:px-6">

                  <div class="hidden sm:block w-1/5 items-center">
                    <div class="flex-shrink-0">
                      <partner-logo :partner="template.partner" />
                    </div>
                  </div>


                  <div class="hidden sm:block w-1/5 items-center text-sm leading-5 font-medium text-gray-600">
                    
                      {{ template.template_type.name }}<br />
                      {{ template.template_type.width }}x{{ template.template_type.height }} px
                    
                  </div>

                  <div
                    class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 items-center"
                  >
                    <div>
                      <div
                        class="text-sm leading-5 font-medium text-gray-600 truncate"
                      >
                        {{ template.name }}
                      </div>
                      <div
                        class="text-sm leading-5 font-normal text-gray-400 truncate-three-lines"
                      >
                        {{ template.note }}
                      </div>
                    </div>
                    <div class="hidden md:block">
                      <div>
                        <div class="mt-2 items-center text-sm">

                          Vorlagen-Status

                          <i
                              class="fa fa-fw fa-circle ml-1 mb-2"
                              :class="template.passes_tests ? 'text-brandhelfer-green' : 'text-brandhelfer-red'"
                            ></i>

                          <p v-if="template.has_correct_syntax_configuration_file" class="mb-2">
                            Umwandlungsservice: {{ serviceName(template.service) }}
                          </p>

                          <button
                            class="block text-gray-400 focus:outline-none hover:text-red-500"
                            @click.stop.prevent="deleteTemplate(template)"
                          >
                            <i class="fa fa-trash fa-fw"></i>
                            Vorlage löschen
                          </button>

                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <svg
                      class="h-5 w-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <div v-if="! templates.length" class="flex h-64">
          <div class="m-auto">
            <p class="text-2xl font-extralight text-gray-400">
              Es liegen keine Vorlagen vor.
            </p>
          </div>
        </div>
        <!-- List: end -->
      </div>
    </div>
  </div>

  <confirm-modal
    v-if="confirmModal.visible"
    :headline="confirmModal.headline"
    :text="confirmModal.text"
    @close="confirmModal.visible = false"
    @confirm="deleteTemplateConfirmed"
  ></confirm-modal>
</layout>
