
<div class="flex flex-row w-full">
  <div class="flex-grow">
    {{ text.name }}<br />

    <input
      type="text"
      v-model="enteredText"
      :class="smallscreen ? 'text-xs' : ''"
      class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
    />

    <div v-if="hasTextColors" class="mt-2">
      <div
        v-for="(color, idx) in textColors"
        :key="idx"
        :style="`background-color: rgba(${color},1)`"
        @click="changeTextColor(color)"
        class="w-2 h-2 rounded float-left border-2 border-gray-200 hover:border-brandhelfer-green cursor-pointer"
        :class="[
          smallscreen ? 'p-3 mr-1 mb-1' : 'p-4 mr-2 mb-2',
          selectedColor == color
            ? 'border-brandhelfer-green cursor-default'
            : ''
        ]"
      ></div>
    </div>
  </div>
  <div class="w-1/5">
    <toggle :idx="text.id" @toggled="statusToggled"></toggle>
  </div>

  <horizontal-divider />
</div>
