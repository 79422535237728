
<div class="ml-3 relative">
  <div>
    <button
      class="flex text-sm border-2 border-transparent rounded-full focus:outline-none transition duration-150 ease-in-out"
      @click="isVisible = !isVisible"
      v-click-outside="hide"
      id="user-menu"
      aria-label="User menu"
      aria-haspopup="true"
    >
      <!-- <partner-logo :partner="$page.props.auth.user.partner" /> -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="text-gray-400 w-8 h-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16m-7 6h7"
        />
      </svg>
    </button>
  </div>
  <!--
            Profile dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-200"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
  -->
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div
      v-if="isVisible"
      class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50"
    >
      <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
        <div class="px-4 py-3">
          <p class="text-sm leading-5">Eingeloggt als</p>
          <p class="text-sm leading-5 font-medium text-gray-900">
            {{ $page.props.auth.user.name }}
          </p>
        </div>
        <div class="border-t border-gray-100"></div>
        <div class="py-1">
          <Link
            :href="route('partners.index')"
            v-if="$page.props.auth.user.is.admin"
            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >Partner</Link
          >

          <Link
            :href="route('users.index')"
            v-if="$page.props.auth.user.is.admin"
            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >User</Link
          >

          <Link
            :href="route('templates.index')"
            v-if="$page.props.auth.user.is.admin"
            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >Vorlagen</Link
          >

          <a
            href="#"
            @click="logout"
            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >Logout</a
          >
        </div>
      </div>
    </div>
  </transition>
</div>
