<template>
  <div class="flex flex-row w-full">
    <div class="flex-grow">
      Hintergrundbild

      <div>
        <button
          v-if="!cropping && !uploading"
          type="button"
          :disabled="sending"
          class="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green"
          @click="browseFiles"
        >

        


          <svg
            class="-ml-0.5 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path>
          </svg>
          Bild hochladen
        </button>
      </div>

      <file-pond
        v-show="!cropping && uploading"
        class="mt-6"
        name="backgroundImage"
        ref="backgroundImagePond"
        :server="backgroundImagePondServerOptions"
        :imageEditEditor="backgroundImageDoka"
        :imageEditInstantEdit="true"
        :imageEditAllowEdit="true"
        :allowDrop="false"
        :allowBrowse="true"
        :maxFiles="100"
        credits=""
        :acceptedFileTypes="['image/jpeg']"
        imageValidateSizeLabelImageSizeTooSmall="Das Bild ist zu klein"
        imageValidateSizeLabelExpectedMinSize="Mindestens {minWidth} × {minHeight} px"
        @init="handleFilePondInit"
        @addfilestart="handleAddFileStart"
        @addfile="handleAddFile"
        @processfile="handleProcessFile"
        :labelIdle="backgroundImagePondLabels['idle']"
        :imageCropAspectRatio="imageCropAspectRatio"
      />
    </div>
    <div class="w-1/5">
      <toggle
        idx="0"
        :sending="sending"
        @toggled="statusToggled"
        :enabled="backgroundImageStatus === true"
      ></toggle>
    </div>
  </div>
</template>

<script>
import Toggle from '@/Shared/Toggle.vue';

// Import Doka
import { create } from '/resources/js/doka/doka.esm.min';
import '/resources/js/doka/doka.min.css';

import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileEncode,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageValidateSize,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginFileMetadata,
  // keep these three plugins at the end of the registration
  // https://stackoverflow.com/questions/65560319/filepond-and-doka-with-vue-js-wrong-initial-crop-area
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginImageTransform
);

export default {
  components: {
    Toggle,
    FilePond,
  },

  props: {
    backgroundImage: Object,
  },

  data() {
    return {
      sending: false,
      cropping: false,
      uploading: false,

      uploadedBackgroundImage: null,
      backgroundImageStatus: false,

      imageCropAspectRatio: `${this.backgroundImage.width}:${this.backgroundImage.height}`,
      // imageCropAspectRatio: '2:1',

      backgroundImagePondServerOptions: {
        url: '/social-graphics/background-image/upload',
        process: {
          headers: {
            'X-CSRF-TOKEN': document.head.querySelector(
              'meta[name="csrf-token"]'
            ).content,
          },
          onload: (response) => {
            this.uploadedBackgroundImage = response;
          },
        },
      },

      backgroundImagePondLabels: {
        idle: '',
        imageValidateSizeLabelImageSizeTooSmall: 'Das Bild ist zu klein',
        imageValidateSizeLabelExpectedMinSize:
          'Mindestens {minWidth} × {minHeight} px',
        labelFileProcessing: 'Hochladen',
        labelFileProcessingComplete: 'Hochgeladen',
        labelTapToCancel: '',
        labelTapToRetry: '',
        labelTapToUndo: '',
        labelTapToCancel: 'Klicken um abzubrechen',
      },

      backgroundImageDoka: create({
        labelStatusLoadingImage: 'Bild wird geladen ...',
        labelButtonUtilCrop: 'Schneiden',
        labelButtonUtilColor: 'Farben',
        labelButtonConfirm: 'Fertig',
        labelButtonCancel: 'Abbrechen',
        labelColorBrightness: 'Helligkeit',
        labelColorContrast: 'Kontrast',
        labelColorExposure: 'Beleuchtung',
        labelColorSaturation: 'Sättigung',
        labelButtonCropRotateLeft: 'Nach links drehen',
        labelButtonCropRotateRight: 'Nach rechts drehen',
        labelButtonCropFlipHorizontal: 'Horizontal spiegeln',
        labelButtonCropFlipVertical: 'Vertikal spiegeln',

        utils: ['crop', 'filter', 'color'],

        // do not use cropAspectRatio here since Doka gets the ratio
        // from filepond
        // https://stackoverflow.com/questions/65560319/filepond-and-doka-with-vue-js-wrong-initial-crop-area
        // cropAspectRatio: this.backgroundImage.height / this.backgroundImage.width,

        cropShowSize: true,

        imagePreviewScaleMode: 'crop',

        cropMinImageWidth: this.backgroundImage.width,
        outputWidth: this.backgroundImage.width,
        outputHeight: this.backgroundImage.height,
        outputQuality: 100,
        outputFit: 'contain',

        outputData: true,

        onconfirm: (output) => {
          this.cropping = false;
        },
        oncancel: () => {
          this.cropping = false;
        },
      }),
    };
  },

  methods: {
    handleFilePondInit: function () {
      console.log('FilePond init');
    },

    handleAddFileStart: function () {
      console.log('FilePond handleAddFileStart');

      this.cropping = true;
    },

    handleAddFile: function () {
      this.uploading = true;
      this.$emit('imageUploading', true);
    },

    handleProcessFile: function () {
      this.uploading = false;
      this.$emit('imageUploading', false);

      this.backgroundImageStatus = true;

      this.$emit('imageUploaded', this.uploadedBackgroundImage);
    },

    browseFiles: function () {
      this.$refs.backgroundImagePond.browse();
    },

    statusToggled(idx, status) {
      this.$emit('statusToggled', status);
    },
  },
};
</script>

<style scoped>
.filepond--panel-root {
  background-color: transparent !important;
}
</style>
