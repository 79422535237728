
<span 
  class="rounded-md shadow-sm"
  :class="fullWidth ? 'w-full block' : 'inline-block text-right'"
>
  <button
    type="submit"
    class="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 focus:outline-none focus:border-red-700 focus:ring-red-500 active:bg-red-700 transition duration-150 ease-in-out"
    :class="[sending || !validated ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-500', fullWidth ? 'w-full' : 'text-right']"
    :disabled="sending || !validated"
  >
    <i v-if="sending" class="fa fa-spinner fa-spin fa-lg fa-fw mr-2 mt-1"></i>
    <slot />
  </button>
</span>
