
<blank-layout>
  <div class="bg-white text-gray-600 h-screen m-0">
      <div class="items-center flex justify-center relative h-screen">
          <div class="border-r-2 border-solid text-4xl pt-0 pr-5 pb-0 pl-5 text-center">
              {{ title }}
          </div>

          <div class="text-2xl text-center pl-5">
              {{ description }}
          </div>
      </div>
  </div>
</blank-layout>
