<template>
  <div>
    <div
      v-if="successVisible"
      class="
        bg-brandhelfer-green
        opacity-70
        absolute
        inset-0
        z-50
        flex
        justify-center
        h-full
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-80 w-80 text-white mt-44"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>

    <div
      v-if="errorVisible"
      @click="hideError"
      class="
        bg-brandhelfer-red
        opacity-70
        absolute
        inset-0
        z-50
        flex
        items-center
        flex-col
        h-full
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-60 w-60 text-white mt-20 mb-10"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <p class="text-white text-4xl font-light max-w-4xl">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      successVisible: false,
      errorVisible: false,
    };
  },

  watch: {
    // success: function (val) {
    //   if (val === true && !this.error) {
    //     this.successVisible = true;

    //     setTimeout(() => {
    //       this.successVisible = false;
    //       this.$page.props.flash.success = false;
    //     }, 1000);
    //   }
    // },

    success: {
      immediate: true,
      handler(val) {
        if (val === true && !this.error) {
          this.successVisible = true;

          setTimeout(() => {
            this.successVisible = false;
            this.$page.props.flash.success = false;
          }, 1000);
        }
      },
    },

    error: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.errorVisible = true;

          setTimeout(() => {
            this.hideError();
          }, 2000);
        }
      },
    },
  },

  methods: {
    hideError() {
      this.errorVisible = false;
      this.$page.props.error = '';
    },
  },

  computed: {
    success() {
      return this.$page.props.flash.success;
    },

    error() {
      return this.$page.props.error;
    },
  },
};
</script>
