<template>
  <div class="flex flex-row w-full">
    <div class="flex-grow">
      {{ text.name }}<br />

      <input
        type="text"
        v-model="enteredText"
        :class="smallscreen ? 'text-xs' : ''"
        class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
      />

      <div v-if="hasTextColors" class="mt-2">
        <div
          v-for="(color, idx) in textColors"
          :key="idx"
          :style="`background-color: rgba(${color},1)`"
          @click="changeTextColor(color)"
          class="w-2 h-2 rounded float-left border-2 border-gray-200 hover:border-brandhelfer-green cursor-pointer"
          :class="[
            smallscreen ? 'p-3 mr-1 mb-1' : 'p-4 mr-2 mb-2',
            selectedColor == color
              ? 'border-brandhelfer-green cursor-default'
              : ''
          ]"
        ></div>
      </div>
    </div>
    <div class="w-1/5">
      <toggle :idx="text.id" @toggled="statusToggled"></toggle>
    </div>

    <horizontal-divider />
  </div>
</template>

<script>
import Toggle from '@/Shared/Toggle.vue';


export default {
  components: {
    Toggle,
    
  },

  props: {
    text: Object,
    smallscreen: {
      default: false,
      required: false
    }
  },

  data() {
    return {
      enteredText: this.placeholder(),
      selectedColor: this.text.defaultColor,
    };
  },

  mounted() {
    if(this.placeholder()) {
      this.$emit('textEntered', this.text, this.placeholder());
    }
  },

  watch: {
    enteredText: {
      handler() {
        this.$emit('textEntered', this.text, this.enteredText);
      },
      deep: true,
    },
  },

  methods: {
    changeTextColor(color) {
      this.selectedColor = color;

      this.$emit('textColorChanged', this.text, color);
    },

    statusToggled(idx, status) {
      this.$emit('textStatusToggled', this.text, status);
    },

    placeholder() {
      
      if(typeof this.text.placeholder === 'object' && this.text.placeholder !== null) {
        if(this.text.placeholder.type == 'current_date') {
          // no need to import moment.js for only one usage. maybe later ...
          // return moment().format('DD.MM.YYYY');

          return new Date(Date.now()).toLocaleString('de-DE').split(',')[0];
        }
      }

      return this.text.placeholder;
    },
  },

  computed: {
    textColors() {
      return this.text.colors;
    },

    hasTextColors() {
      return this.textColors.length > 0;
    },

    
  },
};
</script>
