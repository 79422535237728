<template>
  <layout>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <!-- Page Header: start -->
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <h2
                class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate"
              >Partners</h2>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <span class="sm:ml-3 shadow-sm rounded-md">
                <Link
                  :href="route('partners.create')"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >Neuer Partner</Link>
              </span>
            </div>
          </div>
          <!-- Page Header: end -->

          <horizontal-divider />

          <!-- Filter: start -->
          <div class="flex mb-4">
            <div class="w-11/12 mr-2">
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                type="search"
                  class="mt-1 pl-10 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Suchen nach..."
                  v-model="form.search"
                />
              </div>
            </div>

            <div class="w-1/12 text-center mt-3">
              <button v-if="isFiltered" v-tooltip.left="'Filter zurücksetzen'" @click="reset">
                <i class="fa fa-times fa-fw text-gray-500"></i>
              </button>

              <i v-if="!isFiltered" class="fa fa-times fa-fw text-gray-200"></i>
            </div>
          </div>
          <!-- Filter: end -->

          <!-- List: start -->
          <div class="bg-white shadow overflow-hidden sm:rounded-md">
            <ul>
              <li
                v-for="(partner, idx) in partners"
                :key="partner.id"
                :class="idx ? 'border-t border-gray-200' : ''"
              >
                <a
                  :href="route('partners.edit', { id: partner.id })"
                  class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="hidden sm:block w-1/5 items-center">
                      <div class="flex-shrink-0">
                        <partner-logo :partner="partner" />
                      </div>
                    </div>

                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <div
                          class="text-sm leading-5 font-medium text-gray-600 truncate"
                        >{{ partner.name }}</div>
                        <div class="text-sm leading-5 font-normal text-gray-400">{{ partner.note }}</div>
                      </div>
                      <div class="hidden md:block">
                        <div>
                          <div class="text-sm leading-5 text-gray-900">
                            <Link
                              :href="route('users.index', {partner_id: partner.id})"
                            >{{ usersInfo(partner.users.length) }}</Link>
                          </div>

                          <div class="mt-2 items-center text-sm">
                            <button
                              v-if="!partner.users.length"
                              class="text-gray-400 focus:outline-none hover:text-red-500"
                              @click.stop.prevent="deletePartner(partner)"
                            >
                              <i class="fa fa-trash"></i>
                              Partner löschen
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- List: end -->
        </div>
      </div>
    </div>

    <confirm-modal
      v-if="confirmModal.visible"
      :headline="confirmModal.headline"
      :text="confirmModal.text"
      @close="confirmModal.visible = false"
      @confirm="deletePartnerConfirmed"
    ></confirm-modal>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue';

import PartnerLogo from '@/Shared/PartnerLogo.vue';
// TODO: extract the confirmation modal to a decoupled component
import ConfirmModal from '@/Shared/ConfirmModal.vue';
import { pickBy, debounce, mapValues, some, isNotEmpty } from 'lodash';

export default {
  metaInfo: { title: 'Partner' },

  components: {
    Layout,
    
    PartnerLogo,
    ConfirmModal,
  },

  props: ['filters', 'partners'],

  data() {
    return {
      confirmModal: {
        visible: false,
        itemId: null,
        headline: 'Partner löschen',
        text: '',
      },
      form: {
        search: this.filters.search,
      },
    };
  },

  computed: {
    isFiltered() {
      return some(this.form, isNotEmpty);
    },
  },

  watch: {
    form: {
      handler: debounce(function () {
        let query = pickBy(this.form);
        this.$inertia.get(
          this.route(
            'partners.index',
            Object.keys(query).length ? query : { remember: 'forget' }
          )
        );
      }, 250),
      deep: true,
    },
  },

  methods: {
    usersInfo(num) {
      if (!num) {
        return 'Dieser Partner hat noch keine User.';
      }

      if (num == 1) {
        return 'Dieser Partner hat einen User.';
      }

      return `Dieser Partner hat ${num} User.`;
    },
    reset() {
      this.form = mapValues(this.form, () => null);
    },
    deletePartner(partner) {
      this.confirmModal.itemId = partner.id;
      this.confirmModal.text = `Soll der Partner ${partner.name} wirklich gelöscht werden?`;
      this.confirmModal.visible = true;
    },
    deletePartnerConfirmed() {
      
      if (!this.confirmModal.itemId) return;

      this.sending = true;

      this.$inertia.delete(
        route('partners.delete', {
          id: this.confirmModal.itemId,
        }),
        {
          preserveScroll: true,
          onSuccess: (page) => {
            this.sending = false;
            this.confirmModal.visible = false;
          },
        }
      );

    },
  },
};
</script>
