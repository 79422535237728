<template>
  <div class="flex flex-row w-full">
    <div class="flex-grow">
      {{ display.name }}
    </div>
    <div class="w-1/5">
      <toggle
        :idx="display.id"
        :enabled="enabled"
        @toggled="statusToggled"
      ></toggle>
    </div>
  </div>
</template>

<script>
import Toggle from '@/Shared/Toggle.vue';

export default {
  components: {
    Toggle,
  },

  props: {
    display: Object,
    enabled: true
  },
  
  methods: {
    statusToggled(element, status) {
      this.$emit('statusToggled', this.display, status);
    },
  },

  computed: {
    
  },
};
</script>
