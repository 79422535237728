
<layout-smallscreen active-menu-item="index">
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div
        class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      >
        <!-- Page Header: start -->
        <div class="md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <h2
              class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate"
            >
              Social Graphics
            </h2>
          </div>
        </div>
        <!-- Page Header: end -->

        <horizontal-divider v-if="socialGraphicsTotal" :padding="2" />

        <!-- Filter: start -->
        <div
          v-if="socialGraphicsTotal"
          class="flex flex-col sm:flex-row mb-4"
        >
          <div class="mr-2 sm:w-8/12">
            <div class="mt-1 relative rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  class="h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="search"
                class="mt-1 pl-10 py-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Suchen nach..."
                v-model="form.search"
              />
            </div>
          </div>

          <div class="sm:w-4/12 flex">
            <div class="w-10/12 sm:w-11/12 mr-2">
              <select
                v-model="form.template_type_id"
                class="mt-1 block w-full py-1 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
              >
                <option :value="null">Alle Arten</option>
                <option
                  v-for="templateType in templateTypes"
                  :value="templateType.id"
                  :key="templateType.id"
                >
                  {{ templateType.name }}
                </option>
              </select>
            </div>

            <div class="w-2/12 sm:w-1/12 text-center mt-3">
              <button
                v-if="isFiltered"
                v-tooltip.left="'Filter zurücksetzen'"
                @click="reset"
              >
                <i class="fa fa-times fa-fw text-gray-500"></i>
              </button>

              <i
                v-if="!isFiltered"
                class="fa fa-times fa-fw text-gray-200"
              ></i>
            </div>
          </div>
        </div>
        <!-- Filter: end -->

        <!-- List: start -->

        <div
          v-masonry
          transition-duration="0.3s"
          item-selector=".item"
          v-lazy-container="{ selector: 'img.masonry' }"
        >
          <a
            :href="
              route('social-graphics.download', { id: socialGraphic.id })
            "
            v-masonry-tile
            class="item block w-28 mr-2 mb-2 relative rounded-md"
            v-for="socialGraphic in socialGraphics"
            :key="socialGraphic.id"
            @mouseover="revealInfo = socialGraphic.id"
            @mouseleave="revealInfo = null"
          >
            <spinner :busy="loading" color="#000"></spinner>

            <div
              class="bg-gray-900 opacity-90 w-full h-full absolute top-0 left-0 p-2 rounded-md"
              v-show="revealInfo == socialGraphic.id"
            >
              <p class="text-sm font-light text-white">
                {{ socialGraphic.template.template_type.name }}
              </p>
              <p class="text-sm font-light text-white">
                {{ $filters.formatDateTime(socialGraphic.created_at) }}
              </p>
              <p class="text-sm font-light text-white">
                {{ $filters.formatFilesize(socialGraphic.filesize) }}
              </p>

              <div class="absolute bottom-4 right-4 z-20">
                <i
                  @click.stop.prevent="deleteSocialGraphic(socialGraphic)"
                  class="fa fa-fw fa-3xl fa-trash text-gray-400"
                ></i>
              </div>
            </div>
            <img
              v-bind:data-src="socialGraphic.thumbnail_link"
              class="rounded-md masonry"
              :style="`height: ${socialGraphic.thumbnail_height}px;`"
              :data-original-height="socialGraphic.thumbnail_height"
              @load="adjustImageHeight"
            />
          </a>
        </div>

        <div v-if="message" class="flex h-64">
          <div class="m-auto">
            <p class="text-2xl font-extralight text-gray-400">
              {{ message }}
            </p>
          </div>
        </div>

        <!-- List: end -->
      </div>
    </div>
  </div>

  <confirm-modal
    v-if="confirmModal.visible"
    :headline="confirmModal.headline"
    :text="confirmModal.text"
    @close="confirmModal.visible = false"
    @confirm="deleteSocialGraphicConfirmed"
  ></confirm-modal>
</layout-smallscreen>
