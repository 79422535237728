<template>
  <div>

    <span 
      v-if="!partner.logo_link"
      class="inline-flex items-center justify-center w-auto bg-gray-200 rounded-md px-2"
      :class="`h-${height}`"
    >
      <span class="text-sm font-normal leading-5 text-gray-500">{{ partner.name }}</span>
    </span>

    

    <img v-if="partner.logo_link" class="max-h-8" :style="customCss" :src="partner.logo_link" alt />
  </div>
</template>

<script>
export default {
  props: {
    partner: {
      default: Object
    },
    height: {
      default: 8
    },
    applyCustomCss: {
      default: false
    }
  },

  computed: {
    customCss() {
      if(!this.applyCustomCss) return;

      let css = '';

      if (this.partner.logo_height) {
        css += 'height: ' + this.partner.logo_height + 'px; ';
      }
      if (this.partner.logo_margin_top) {
        css += 'margin-top: ' + this.partner.logo_margin_top + 'px;';
      }

      return css;
    }
  }
};
</script>