
<div
  class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
>
  <!--
  Notification panel, show/hide based on alert state.

  Entering: "transform ease-out duration-300 transition"
    From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    To: "translate-y-0 opacity-100 sm:translate-x-0"
  Leaving: "transition ease-in duration-100"
    From: "opacity-100"
    To: "opacity-0"
  -->
  <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition ease-in duration-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto"
      :class="isError ? 'bg-red-100' : 'bg-white'"
      v-if="isVisible"
      v-click-outside="hide"
    >
      <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <svg
                v-if="!isError"
                class="h-6 w-6 text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                v-if="isError"
                class="h-6 w-6 text-red-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm leading-5 font-medium text-gray-900">Hinweis</p>
              <p
                class="mt-1 text-sm leading-5 text-gray-500"
              >{{ isError ? $page.props.error : $page.props.status }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</div>
