<template>
  <layout>
    <div class="bg-white shadow rounded-lg">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-1">
        <div class="py-6 md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <div>
                <div class="flex items-center">
                  <h1
                    class="ml-3 text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate"
                  >
                    {{ salutation }}, {{ $page.props.auth.user.name }}
                  </h1>
                </div>
                <dl
                  class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap"
                >
                  <dd
                    class="flex items-center text-sm leading-5 text-cool-gray-500 font-medium sm:mr-6"
                  >
                    {{ $page.props.auth.user.partner.name }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <span class="shadow-sm rounded-md">
              <Link
                :href="route('social-graphics.index')"
                class="inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none active:text-cool-gray-800 active:bg-cool-gray-50 transition duration-150 ease-in-out"
                >Übersicht</Link
              >
            </span>
            <span class="shadow-sm rounded-md">
              <Link
                :href="route('social-graphics.select-template')"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-brandhelfer-red hover:bg-red-700 focus:outline-none active:bg-brandhelfer-red transition duration-150 ease-in-out"
                >Neue Social Graphic anlegen</Link
              >
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="sm:mt-5 px-3 sm:px-0 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <Link
        tag="div"
        :href="route('social-graphics.index')"
        class="bg-white overflow-hidden shadow rounded-lg"
      >
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
              Social Graphics | {{ $page.props.auth.user.name }}
            </dt>
            <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900">
              {{ mySocialGraphicsTotal }}
            </dd>
          </dl>
        </div>
      </Link>

      <Link
        tag="div"
        :href="route('social-graphics.index')"
        class="bg-white overflow-hidden shadow rounded-lg"
      >
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
              Social Graphics | {{ $page.props.auth.user.partner.name }}
            </dt>
            <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900">
              {{ partnerSocialGraphicsTotal }}
            </dd>
          </dl>
        </div>
      </Link>

      <Link
        tag="div"
        :href="route('social-graphics.index')"
        class="bg-white overflow-hidden shadow rounded-lg"
      >
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
              Vorlagen
            </dt>
            <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900">
              {{ templatesTotal }}
            </dd>
            <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
              davon genutzt: {{ usedTemplates }} ({{
                $filters.percentage(usedTemplatesPercentage)
              }}
              %)
            </dt>
          </dl>
        </div>
      </Link>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue';
import PartnerLogo from '@/Shared/PartnerLogo.vue';

export default {
  components: {
    Layout,
    PartnerLogo,
  },

  props: [
    'templatesTotal',
    'usedTemplates',
    'usedTemplatesPercentage',
    'mySocialGraphicsTotal',
    'partnerSocialGraphicsTotal',
  ],

  computed: {
    salutation() {
      var today = new Date();
      var hour = today.getHours();

      if (hour >= 5 && hour < 11) {
        return 'Guten Morgen';
      }

      if (hour >= 11 && hour <= 18) {
        return 'Guten Tag';
      }

      if (hour > 18 && hour <= 0) {
        return 'Guten Abend';
      }

      return 'Hallo';
    },
  },

  filters: {
    percentage: function (value) {
      if (!value) return '0,0';

      return Math.round(value * 100 * 10) / 10;
    },
  },
};
</script>
