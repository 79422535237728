
<div class="flex flex-row w-full">
  <div class="flex-grow">
    {{ backgroundColor.name }}

    <div class="mt-2">
      <div
        v-for="(color, idx) in backgroundColor.colors"
        :key="idx"
        :style="`background-color: rgba(${color},1)`"
        @click="changeBackgroundColor(color)"
        class="w-2 h-2 rounded float-left border-2 border-gray-200 hover:border-brandhelfer-green cursor-pointer"
        :class="[
          smallscreen ? 'p-3 mr-1 mb-1' : 'p-4 mr-2 mb-2',
          selectedBackgroundColor == color
            ? 'border-brandhelfer-green cursor-default'
            : ''
        ]"
      ></div>
    </div>
  </div>
  <div class="w-1/5">
    <toggle
      :idx="backgroundColor.id"
      :enabled="backgroundColor.enabled"
      @toggled="statusToggled"
    ></toggle>
  </div>
</div>
