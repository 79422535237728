<template>
  <div class="w-full">
    <display
      v-for="(option, idx) in display.options"
      :key="idx"
      :display="option"
      :enabled="option.enabled"
      @statusToggled="statusToggled"
      class="mb-6"
    ></display>
  </div>
</template>

<script>
import Display from '@/Shared/Fields/Display.vue';
import { forEach } from 'lodash';

export default {
  components: {
    Display,
  },

  props: {
    display: Object,
  },

  methods: {
    statusToggled(element, status) {
      this.$emit('statusToggled', element, status);
      
      // TODO: let's make this nicer when there is time
      if(status == true) {
        forEach(this.display.options, (option, key) => { 
          if(option.id != element.id) {
            this.$set(this.display.options[key], 'enabled', false);
            this.$emit('statusToggled', option, false);
          } else {
            this.$set(this.display.options[key], 'enabled', true);
          }
        });
      }
    },
  },

  computed: {},
};
</script>
