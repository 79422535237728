
<div class="flex flex-row w-full">
  <div class="flex-grow">
    Hintergrundbild

    <div>
      <button
        v-if="!cropping && !uploading"
        type="button"
        :disabled="sending"
        class="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green"
        @click="browseFiles"
      >

      


        <svg
          class="-ml-0.5 mr-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path>
        </svg>
        Bild hochladen
      </button>
    </div>

    <file-pond
      v-show="!cropping && uploading"
      class="mt-6"
      name="backgroundImage"
      ref="backgroundImagePond"
      :server="backgroundImagePondServerOptions"
      :imageEditEditor="backgroundImageDoka"
      :imageEditInstantEdit="true"
      :imageEditAllowEdit="true"
      :allowDrop="false"
      :allowBrowse="true"
      :maxFiles="100"
      credits=""
      :acceptedFileTypes="['image/jpeg']"
      imageValidateSizeLabelImageSizeTooSmall="Das Bild ist zu klein"
      imageValidateSizeLabelExpectedMinSize="Mindestens {minWidth} × {minHeight} px"
      @init="handleFilePondInit"
      @addfilestart="handleAddFileStart"
      @addfile="handleAddFile"
      @processfile="handleProcessFile"
      :labelIdle="backgroundImagePondLabels['idle']"
      :imageCropAspectRatio="imageCropAspectRatio"
    />
  </div>
  <div class="w-1/5">
    <toggle
      idx="0"
      :sending="sending"
      @toggled="statusToggled"
      :enabled="backgroundImageStatus === true"
    ></toggle>
  </div>
</div>
