
<div>
  <p v-if="!log.length" class="text-sm font-medium text-gray-700">
    Es liegen noch keine Aktivitäten vor.
  </p>

  <div class="flow-root">
    <ul class="-mb-8">
      <li v-for="(activity, idx) in log" :key="activity.id">
        <div class="relative pb-8">
          <span
            v-if="idx < log.length - 1"
            class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          ></span>
          <div class="relative flex space-x-3">
            <div>
              <log-icon :log="activity"></log-icon>
            </div>
            <div
              class="
                min-w-0
                flex-1
                pt-1.5
                sm:flex sm:justify-between sm:space-x-4
              "
            >
              <div>
                <p class="text-sm text-gray-500">
                  {{ activityType(activity.description) }} {{ activity.id }}
                  <span class="font-medium text-gray-900">{{
                    activitySubject(activity.subject_type)
                  }}</span>
                  &quot;{{ activityDetail(activity) }}&quot;
                </p>
                <p class="mt-2 text-sm text-gray-400">
                  {{ activityChanges(activity) }}
                </p>
              </div>
              <div
                class="
                  sm:block sm:text-right
                  text-sm
                  whitespace-nowrap
                  text-gray-500
                "
              >
                <time>{{
                  $filters.formatDateTime(activity.created_at)
                }}</time>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
