
<div class="flex flex-row w-full">
  <div class="flex-grow">
    {{ display.name }}
  </div>
  <div class="w-1/5">
    <toggle
      :idx="display.id"
      :enabled="enabled"
      @toggled="statusToggled"
    ></toggle>
  </div>
</div>
