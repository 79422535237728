<template>
  <blank-layout>
    <div class="bg-white text-gray-600 h-screen m-0">
        <div class="items-center flex justify-center relative h-screen">
            <div class="border-r-2 border-solid text-4xl pt-0 pr-5 pb-0 pl-5 text-center">
                {{ title }}
            </div>

            <div class="text-2xl text-center pl-5">
                {{ description }}
            </div>
        </div>
    </div>
  </blank-layout>
</template>

<script>
import BlankLayout from "@/Shared/BlankLayout.vue";

export default {
  components: {
    BlankLayout,
  },
  props: {
    status: Number,
  },
  computed: {
    title() {
      return {
        503: '503',
        500: '500',
        419: '419',
        404: '404',
        403: '403',
      }[this.status]
    },
    description() {
      return {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        419: 'Sorry, this page has expired.',
        404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.',
      }[this.status]
    },
  },
}
</script>