<template>
  <div>
    <div class="clear-both w-full mt-12">
      <button
        type="button"
        :disabled="loading"
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        @click.stop="browseFiles"
      >
        <svg
          class="-ml-0.5 mr-2 h-5 w-5 -mt-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        Bild aus Bibliothek wählen
      </button>
    </div>

    <!-- sidebar: start -->
    <!-- <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden"> -->
    <!--
      Background overlay, show/hide based on slide-over state.

      Entering: "ease-in-out duration-500"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in-out duration-500"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <transition
      enter-active-class="ease-in-out duration-500"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in-out duration-500"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="sidebarOpened"
        class="absolute inset-0 bg-gray-100 bg-opacity-25 transition-opacity"
        aria-hidden="true"
      ></div>
    </transition>

    <section
      v-click-outside="closeSidebar"
      class="absolute z-20 inset-y-0 right-0 pl-10 max-w-full flex"
      aria-labelledby="slide-over-heading"
    >
      <!--
        Slide-over panel, show/hide based on slide-over state.

        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-0"
          To: "translate-x-full"
      -->
      <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
        enter-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
        leave-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <div v-if="sidebarOpened" class="w-screen max-w-md">
          <div
            class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
          >
            <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
              <div class="px-4 sm:px-6">
                <div class="flex items-start justify-between">
                  <h2
                    id="slide-over-heading"
                    class="text-lg font-medium text-gray-900"
                  >
                    Bild-Bibliothek
                  </h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button
                      type="button"
                      @click="closeSidebar"
                      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: x -->
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-6 relative flex-1 px-4 sm:px-6">
                <spinner :busy="loading" color="#000"></spinner>
                

                <div v-if="images.length" class="grid grid-cols-2 gap-3">
                  <img
                    v-for="(image, idx) in images"
                    :key="idx"
                    :src="
                      route('social-graphics.background-image-library.thumb-link', {
                        template: template,
                        image: image,
                      })
                    "
                    @click="selectImage(image)"
                    class="cursor-pointer border-4 border-transparent opacity-80 hover:border-brandhelfer-green hover:opacity-100 transform hover:scale-105"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </section>
    <!-- </div>
    </div> -->
    <!-- sidebar: end -->
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Spinner from '@/Shared/Spinner.vue';

export default {
  components: {
    Spinner,
  },

  directives: {
    ClickOutside,
  },

  props: {
    template: Object,
  },

  data() {
    return {
      loading: false,
      sidebarOpened: false,
      images: [],
      selectedBackgroundImage: null,
    };
  },

  methods: {
    closeSidebar: function () {
      this.sidebarOpened = false;
    },

    browseFiles: function () {
      this.sidebarOpened = true;
      this.loading = true;

      window.axios
        .get(
          route('social-graphics.background-image-library.images', {
            template: this.template, 
          })
        )
        .then((response) => {
          this.images = response.data;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
        });
    },

    selectImage: function (image) {
      this.$emit('imageSelected', image);

      // smallscreen only:
      this.sidebarOpened = false;
    },
  },
};
</script>