
<div class="h-full min-h-full flex flex-col bg-gray-100">
  <div class="order-2 overflow-y-scroll">
    <!-- <navbar></navbar> -->
    <impersonation-alert></impersonation-alert>

    <div class="py-5">
      <main>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <slot></slot>
        </div>
      </main>
    </div>

    <toast></toast>
  </div>

  <navbar-smallscreen active-item="activeMenuItem"></navbar-smallscreen>
</div>
