
<div>
  <span
    v-if="log.description == 'created'"
    class="h-8 w-8 rounded-full bg-brandhelfer-green flex items-center justify-center ring-8 ring-white"
  >
    <svg
      class="h-6 w-6 text-white"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5 13l4 4L19 7"
      ></path>
    </svg>
  </span>

  <span
    v-if="log.description == 'updated'"
    class="h-8 w-8 rounded-full bg-brandhelfer-blue flex items-center justify-center ring-8 ring-white"
  >
    <svg
      class="h-5 w-5 text-white"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
      ></path>
    </svg>
  </span>

  <span
    v-if="log.description == 'deleted'"
    class="h-8 w-8 rounded-full bg-brandhelfer-red flex items-center justify-center ring-8 ring-white"
  >
    <svg
      class="h-5 w-5 text-white"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      ></path>
    </svg>
  </span>

  <span
    v-if="log.description == 'downloaded'"
    class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
  >
    <svg
      class="h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
      />
    </svg>
  </span>
</div>
