<template>
  <layout>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div
          class="-my-2 py-2 sm:overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <!-- Page Header: start -->
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <h2
                class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate"
              >
                User &bdquo;{{ user.name }}&ldquo;
              </h2>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <span class="sm:ml-3 shadow-sm rounded-md">
                <Link
                  :href="route('users.index')"
                  preserve-scroll
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                  >Übersicht</Link
                >
              </span>
            </div>
          </div>
          <!-- Page Header: end -->

          <!-- Tabs: start -->
          <div class="mt-4 mb-8">
            <div class="border-b border-gray-200">
              <nav class="-mb-px flex space-x-8">
                <Link
                  :href="route('users.edit', { id: user.id })"
                  class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                >
                  Profil
                </Link>
                <Link
                  :href="route('users.log', { id: user.id })"
                  class="border-brandhelfer-red text-brandhelfer-red whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                >
                  Log
                </Link>
              </nav>
            </div>
          </div>
          <!-- Tabs: end -->

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Aktivitäten
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <!-- Filter: start -->
                <div class="flex mb-4">
                  <div class="w-11/12 mr-2">
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div
                        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                      >
                        <svg
                          class="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        class="mt-1 pl-10 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Suchen nach..."
                        v-model="form.search"
                      />
                    </div>
                  </div>

                  <div class="w-1/12 text-center mt-3">
                    <button
                      v-if="isFiltered"
                      v-tooltip.left="'Filter zurücksetzen'"
                      @click="reset"
                    >
                      <i class="fa fa-times fa-fw text-gray-500"></i>
                    </button>

                    <i
                      v-if="!isFiltered"
                      class="fa fa-times fa-fw text-gray-200"
                    ></i>
                  </div>
                </div>
                <!-- Filter: end -->

                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <log :log="log"></log>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue';

import Log from '@/Pages/Users/Shared/Log.vue';
import { pickBy, debounce, mapValues, some, isNotEmpty } from 'lodash';

export default {
  metaInfo: { title: 'User-Log' },

  components: {
    Layout,
    
    Log,
  },

  props: {
    user: Object,
    log: Array,
    filters: Object,
  },

  data() {
    return {
      form: {
        search: this.filters.search,
      },
    };
  },

  computed: {
    isFiltered() {
      return some(this.form, isNotEmpty);
    },
  },

  watch: {
    form: {
      handler: debounce(function () {
        let query = pickBy(this.form);
        query.id = this.user.id;
        // console.log(query);
        this.$inertia.replace(
          this.route(
            'users.log',
            Object.keys(query).length ? query : { remember: 'forget' }
          )
        );
      }, 120),
      deep: true,
    },
  },

  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    },
  },
};
</script>
