<template>
  <layout>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div
          class="-my-2 py-2 overflow-x-auto overflow-y-hidden sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <!-- Page Header: start -->
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                <h2
                  class="ml-2 mt-2 text-2xl leading-7 font-light text-gray-900"
                >
                  Social Graphics
                </h2>
                <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
                  Vorlage auswählen
                </p>
              </div>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <span class="sm:ml-3 shadow-sm rounded-md">
                <Link
                  :href="route('social-graphics.index')"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                  >Übersicht</Link
                >
              </span>
            </div>
          </div>
          <!-- Page Header: end -->

          <horizontal-divider />

          <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-6">
            <li
              class="relative col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 hover:bg-gray-200 cursor-pointer"
              :class="!selectedTemplateType ? 'bg-gray-200' : ''"
              @click="selectedTemplateType = null"
            >
              <div class="w-full flex items-center justify-between p-3">
                <h3 class="text-gray-900 text-sm font-medium truncate">
                  Alle Vorlagen-Arten
                </h3>
              </div>
            </li>

            <li
              v-for="template_type in template_types"
              :key="template_type.id"
              class="relative col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 hover:bg-gray-200 cursor-pointer"
              :class="selectedTemplateType == template_type.id ? 'bg-gray-200' : ''"
              @click="selectedTemplateType = template_type.id"
            >
              <div class="w-full flex items-center justify-between p-3">
                <div class="flex-1 truncate">
                  <div class="flex items-center space-x-3">
                    <h3 class="text-gray-900 text-sm font-medium truncate">
                      {{ template_type.name }}
                    </h3>
                  </div>
                  <p class="mt-1 text-gray-500 text-sm truncate">
                    {{ template_type.width }} x {{ template_type.height }} px
                  </p>

                  
                </div>
              </div>
            </li>
          </ul>

          <div
            v-masonry
            transition-duration="0.3s"
            item-selector=".item"
            v-lazy-container="{ selector: 'img.masonry' }"
            class="mt-4"
          >
            <a
              v-for="template in filteredTemplates"
              :key="template.id"
              :href="route('social-graphics.create', { template: template })"
              v-masonry-tile
              class="item block w-64 mr-5 mb-5 relative rounded-md"
              @mouseover="revealInfo = template.id"
              @mouseleave="revealInfo = null"
            >
              <spinner :busy="loading" color="#000"></spinner>
              
              <div
                class="bg-gray-900 opacity-90 w-full h-full absolute top-0 left-0 p-6 rounded-md"
                v-show="revealInfo == template.id"
              >
                <p class="text-lg font-light text-white">
                  {{ template.name }}
                </p>
                <p class="text-lg font-light text-white">
                  {{ template.template_type.name }}
                </p>
                <p class="text-lg font-light text-white">
                  {{ template.template_type.width }}x{{
                    template.template_type.height
                  }}
                  px
                </p>
                <p class="text-xs font-light text-white mt-6 truncate-three-lines"
                >
                    {{ template.note }}
                </p>
              </div>
              <img
                :data-src="
                  route('social-graphics.preview-image', { template: template })
                "
                class="rounded-md masonry"
                :style="`height: ${template.preview_image_height}px;`"
                :data-original-height="template.preview_image_height"
                @load="adjustImageHeight"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue';

import Spinner from '@/Shared/Spinner.vue';
import { Carousel, Slide } from 'vue-carousel';
import SubmitButton from '@/Shared/SubmitButton.vue';

export default {
  metaInfo: { title: 'Vorlagenauswahl' },

  components: {
    Layout,
    
    Spinner,
    SubmitButton,
  },

  props: {
    template_types: Array,
    templates: Array,
  },

  data() {
    return {
      loading: false,
      sending: false,
      selectedTemplateType: null,
      selectedTemplateIdx: 0,
      revealInfo: null,
    };
  },

  

  methods: {
    

    // TODO: this is just a quick & dirty way to adjust the heights of the images
    // after the widths have been modified by Masonry.
    // Maybe there is a better way or something like VueMasonryWall
    adjustImageHeight(event) {
      let element = event.target;
      
      let originalHeight = element.getAttribute('data-original-height');
      console.log(originalHeight);

      let height = originalHeight * (element.width / 360);
      element.style.height = height + 'px';

      this.loading = false;
    },

    //   submit() {

    //     if(this.templates[this.selectedTemplateIdx] === undefined) {
    //       return false;
    //     }

    //     this.sending = true;

    //     this.$inertia
    //       .visit(
    //         route('social-graphics.create', {
    //           template: this.templates[this.selectedTemplateIdx],
    //         }),
    //         {}
    //       )
    //       .then(() => {
    //         this.sending = false;
    //       });
    //   },
  },

  computed: {
    filteredTemplates() {
      if(!this.selectedTemplateType) {
        return this.templates;
      }

      return this.templates.filter((template) => {
        return template.template_type.id == this.selectedTemplateType;
      })
    }

    
  },
};
</script>

<style>
.VueCarousel svg {
  width: 100% !important;
  height: 100% !important;
}
</style>
