
<layout>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    
    <div class="flex flex-col">
      <div
        class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 "
      >
      
        <!-- Page Header: start -->
        <div class="md:flex md:items-center md:justify-between">
          
          <div class="flex-1 min-w-0">
            <h2
              class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate"
            >
              User &bdquo;{{ form.name }}&ldquo;
            </h2>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <span class="sm:ml-3 shadow-sm rounded-md">
              <Link
                :href="route('users.index')"
                preserve-scroll
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >Übersicht</Link
              >
            </span>
          </div>
        </div>
        <!-- Page Header: end -->

        <!-- Tabs: start -->
        <div class="mt-4 mb-8">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8">
              <Link
                :href="route('users.edit', { id: user.id })"
                class="border-brandhelfer-red text-brandhelfer-red whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              >
                Profil
              </Link>
              <Link
                :href="route('users.log', { id: user.id })"
                class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              >
                Log
              </Link>
            </nav>
          </div>
        </div>
        <!-- Tabs: end -->

        <form @submit.prevent="submit" novalidate>
          <div v-if="$page.props.auth.user.is.admin">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Partner
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-3 gap-6">
                      <div class="col-span-6 sm:col-span-4">
                        <select
                          v-model="form.partner_id"
                          :disabled="form.processing"
                          @change="form.partner_id = $event.target.value"
                          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                        >
                          <option value="">-- bitte wählen --</option>
                          <option
                            v-for="partner in partners"
                            :value="partner.id"
                            :key="partner.id"
                            :selected="partner.id == form.partner_id"
                          >
                            {{ partner.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <horizontal-divider v-if="$page.props.auth.user.is.admin" />

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Profil
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-3 gap-6">
                      <div class="col-span-6 sm:col-span-4">
                        <label
                          for="name"
                          class="block text-sm font-medium leading-5 text-gray-700"
                          >Name</label
                        >
                        <input
                          type="text"
                          id="name"
                          v-model="form.name"
                          :disabled="form.processing"
                          class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div class="col-span-6 sm:col-span-4">
                        <label
                          for="email"
                          class="block text-sm font-medium leading-5 text-gray-700"
                          >E-Mail Adresse</label
                        >
                        <input
                          id="email"
                          type="email"
                          v-model="form.email"
                          :disabled="form.processing"
                          class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="mt-6">
                      <label
                        for="note"
                        class="block text-sm leading-5 font-medium text-gray-700"
                        >Bemerkungen</label
                      >
                      <div class="mt-1">
                        <textarea
                          id="note"
                          v-model="form.note"
                          :disabled="form.processing"
                          rows="5"
                          class="shadow-sm focus:ring-gray-400 focus:border-gray-400 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <horizontal-divider />

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Rechte
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input
                          v-model="form.admin"
                          :disabled="form.processing"
                          id="rights_admin"
                          type="checkbox"
                          class="focus:ring-gray-400 h-4 w-4 text-gray-400 border-gray-300 rounded"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          for="rights_admin"
                          class="font-medium text-gray-700"
                          >Admin</label
                        >
                        <p class="text-gray-500">
                          Kann User eines Partner anlegen und bearbeiten.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <horizontal-divider />

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Vorlagen
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <p v-if="!templates.length">
                      Es liegen keine Vorlagen vor.
                    </p>
                    <div class="grid grid-cols-2 gap-4">
                      <div
                        class="flex items-start"
                        v-for="template in templates"
                        :key="template.id"
                      >
                        <div class="flex items-center h-5">
                          <input
                            :id="`template_${template.id}`"
                            :value="template.id"
                            v-model="form.templates"
                            :disabled="form.processing"
                            type="checkbox"
                            class="focus:ring-gray-400 h-4 w-4 text-gray-400 border-gray-300 rounded"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label
                            for="rights_admin"
                            class="font-medium text-gray-700"
                            ><span class="text-gray-400"
                              >{{ template.template_type.name }}:</span
                            >
                            {{ template.name }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <horizontal-divider />

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Vorlagen anderer Partner
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <p v-if="!templatesOfOtherPartners.length">
                      Es liegen keine Vorlagen vor.
                    </p>
                    <div class="grid grid-cols-2 gap-4">
                      <div
                        class="flex items-start"
                        v-for="template in templatesOfOtherPartners"
                        :key="template.id"
                      >
                        <div class="flex items-center h-5">
                          <input
                            :id="`template_${template.id}`"
                            :value="template.id"
                            v-model="form.templates"
                            :disabled="form.processing"
                            type="checkbox"
                            class="focus:ring-gray-400 h-4 w-4 text-gray-400 border-gray-300 rounded"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label
                            for="rights_admin"
                            class="font-medium text-gray-700"
                            ><span class="text-gray-400"
                              >{{ template.template_type.name }}:</span
                            >
                            {{ template.name }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <horizontal-divider />

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Passwort
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600">
                    Mindestlänge Passwort: 8 Zeichen
                    <br />Mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1
                    Ziffer und 1 Sonderzeichen.
                    <br />
                    <br />Um das aktuelle Passwort zu erhalten, lassen Sie
                    dieses Feld einfach frei.
                  </p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-3 gap-6">
                      <div class="col-span-6 sm:col-span-4">
                        <label
                          for="password"
                          class="block text-sm font-medium leading-5 text-gray-700"
                          >Passwort</label
                        >
                        <input
                          id="password"
                          type="password"
                          class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          v-model="form.password"
                          :disabled="form.processing"
                        />

                        <div
                          class="h-1 bg-brandhelfer-red w-full mt-1 rounded-md"
                          :class="
                            form.password.length && !passwordValidated
                              ? ''
                              : form.password.length && passwordValidated
                              ? 'bg-brandhelfer-green'
                              : 'hidden'
                          "
                        ></div>
                      </div>

                      <div class="col-span-6 sm:col-span-4">
                        <label
                          for="password_confirmation"
                          class="block text-sm font-medium leading-5 text-gray-700"
                          >Passwortwiederholung</label
                        >
                        <input
                          id="password_confirmation"
                          type="password"
                          class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          v-model="form.password_confirmation"
                          :disabled="form.processing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <horizontal-divider />

          <div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <div class="px-4 sm:px-0">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Letzte Aktivitäten
                  </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600"></p>
                </div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <log :log="log"></log>

                    <div v-if="log.length" class="mt-8">
                      <Link
                        :href="route('users.log', { id: user.id })"
                        class="text-sm text-brandhelfer-red hover:text-brandhelfer-red"
                      >
                        Alle Aktivitäten anzeigen
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-8 border-t border-gray-200 pt-5">
            <div class="flex justify-end">
              <submit-button
                :sending="form.processing"
                :validated="validated"
                :full-width="false"
                >Speichern</submit-button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</layout>
