<template>
  <div v-if="isVisible" class="bg-brandhelfer-blue p-3 text-center text-sm leading-5 text-gray-100">
    Ansicht als {{ $page.props.auth.user.name }} ({{ $page.props.auth.user.partner.name }})
    |
    <a
      :href="route('users.impersonation.leave')"
      class="hover:text-white focus:outline-none"
    >Ansicht beenden</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: this.$page.props.auth.user.is.impersonating
    };
  }
};
</script>